import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useCompareValues from "../lib/useCompareValues";
import useScreenWidth from "../lib/useGetScreenWidth";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useSortPenguji from "../globalState/useSortPenguji";
import ComponentSpinner from "./ComponentSpinner";
import TidakAdaData from "./TidakAdaData";
import { Penguji } from "../const/types";
import useTrigger from "../globalState/useTrigger";
import useSearchPenguji from "../globalState/useSearchPenguji";
import AksiUpdateDeletePenguji from "./AksiUpdateDeletePenguji";
import formatNumber from "../lib/formatNumber";
import NoOflinesText from "./NoOflinesText";

// getData(): Promise
//

export default function TabelPenguji() {
  const [initialData, setInitialData] = useState<Penguji[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();

  const [data, setData] = useState(
    initialData !== null ? [...initialData] : null
  );
  useEffect(() => {
    setLoading(true);
    const url = `api/penguji/getall`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setInitialData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [trigger]);

  const { sortBy, sortOrder } = useSortPenguji();
  const compareValues = useCompareValues;
  useEffect(() => {
    if (initialData !== null) {
      const sorted = [...initialData].sort(compareValues(sortBy, sortOrder));
      setData(sorted);
    }
  }, [sortBy, sortOrder, compareValues, initialData]);

  const { searchTerm } = useSearchPenguji();
  const filteredData = data?.filter(
    (d) =>
      d.nama.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.id.toString().includes(searchTerm) ||
      d.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.no_hp.includes(searchTerm)
  );

  const sw = useScreenWidth();

  if (!loading && data && data.length > 0) {
    if (filteredData?.length === 0) {
      return <TidakAdaData />;
    } else {
      return (
        <Box
          borderRadius={8}
          border={"1px solid var(--divider3)"}
          overflow={"auto"}
          className="scrollX scrollY"
          maxH={sw < 770 ? "calc(100vh - 246px)" : "calc(100vh - 200px)"}
        >
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>ID</Th>
                <Th>Nama Lengkap</Th>
                <Th>Email</Th>
                <Th>No.Ponsel</Th>
                <Th w={"64px"}>Aksi</Th>
              </Tr>
            </Thead>

            <Tbody>
              {filteredData?.map((d, i) => (
                <Tr key={i}>
                  <Td>{formatNumber(i + 1)}</Td>
                  <Td>{d.id}</Td>
                  <Td>
                    <NoOflinesText>{d.nama}</NoOflinesText>
                  </Td>
                  <Td>{d.email}</Td>
                  <Td>{d.no_hp}</Td>
                  <Td isNumeric>
                    <AksiUpdateDeletePenguji data={d} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    }
  } else if (!loading && data && data.length === 0) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
