import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DividerFullHorizontal from "./DividerFullHorizontal";
import useFormatNumber from "../lib/useFormatNumber";
import { useParams } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import { DetailTransaksiMetodeUji } from "../const/types";

export default function DetailMetodeUji() {
  const fn = useFormatNumber;

  const [data, setData] = useState<DetailTransaksiMetodeUji | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/product`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg]);

  if (!loading && data) {
    return (
      <Box
        p={4}
        borderRadius={8}
        border={"1px solid var(--divider3)"}
        bg={"var(--divider)"}
      >
        <Text fontWeight={600}>{data.name}</Text>
        <Text opacity={0.5} fontSize={[12, null, 14]} mb={4}>
          {data.kategori.name}
        </Text>

        <Text fontSize={[12, null, 14]}>{data.deskripsi}</Text>

        <DividerFullHorizontal my={4} />

        <Text fontWeight={600} alignSelf={"center"} whiteSpace={"nowrap"}>
          {`Rp ${fn(data.price)}`}
        </Text>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
