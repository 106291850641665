import React, { useEffect, useState } from "react";
import CustomerContainer from "../../components/CustomerContainer";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Container from "../../components/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Kuisioner } from "../../const/types";
import clientRequest from "../../const/clientRequest";
import reqWithHeader from "../../const/reqWithHeader";
import RequiredForm from "../../components/RequiredForm";
import ComponentSpinner from "../../components/ComponentSpinner";

export default function KuisionerPage() {
  const labels = [1, 2, 3, 4, 5];
  const [data, setData] = useState<Kuisioner[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const errorTextColor = useColorModeValue("red.500", "red.200");
  const selectedScoreColor = useColorModeValue("white", "dark");
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);
    const url = `api/feedback/quesioner/get`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((r) => {
          if (r.status === 200) {
            setData(r.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      jawaban: ([] as number[]) || [],
    },

    validationSchema: yup.object().shape({
      jawaban: yup.array().required(),
    }),

    onSubmit: (values, { resetForm }) => {
      const payload = {
        pertanyaan: JSON.stringify(data?.map((kuis) => kuis.id)),
        jawaban: JSON.stringify(values.jawaban),
      };

      console.log(payload);

      setSubmitLoading(true);
      const url = `api/feedback/${noreg}/submit`;
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((r) => {
            if (r.status === 200) {
              toast({
                title: r.data.message,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              navigate("/customer/pengujian");
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      });
    },
  });

  const handleAnswerClick = (index: number, value: number) => {
    // Mengambil nilai jawaban saat ini dari formik
    const currentAnswers = [...formik.values.jawaban];

    // Mengubah nilai jawaban sesuai dengan indeks elemen yang diklik
    currentAnswers[index] = value;

    console.log(currentAnswers);
    // Menetapkan nilai jawaban baru ke formik
    formik.setFieldValue("jawaban", currentAnswers);
  };

  return (
    <CustomerContainer active={""}>
      <Container>
        <Box mb={6}>
          <HStack className="breadcrumb" my={6}>
            <Link className="breadcrumbItem" to="/customer">
              Beranda
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Link className="breadcrumbItem" to="/customer/pengujian">
              Pengujian
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Text className="breadcrumbItem breadcrumbCurrent">
              Kuisioner Kepuasan
            </Text>
          </HStack>

          <VStack>
            <VStack
              p={6}
              borderRadius={16}
              border={"1px solid var(--divider3)"}
              maxW={"600px"}
              align={"stretch"}
            >
              <VStack
                p={6}
                borderRadius={8}
                bg={"p.500"}
                color={useColorModeValue("white", "dark")}
                mb={6}
              >
                <Heading fontSize={24} textAlign={"center"}>
                  Kuisioner Kepuasan Pelanggan
                </Heading>

                <Text textAlign={"center"} fontSize={16}>
                  Kami Peduli pada Kepuasan Anda, Bagikan Pengalaman Anda!
                </Text>
              </VStack>

              <VStack align={"stretch"}>
                <form id="kuisionerForm" onSubmit={formik.handleSubmit}>
                  {loading && <ComponentSpinner h={"400px"} />}

                  {!loading &&
                    data &&
                    data.map((p, index) => (
                      <FormControl mb={6} key={index}>
                        <FormLabel fontWeight={500} fontSize={18} mb={2}>
                          {`${index + 1}. ${p.pertanyaan}`}
                          <RequiredForm />
                        </FormLabel>

                        <HStack justify={"center"} gap={4}>
                          {labels.map((label, i) => {
                            // console.log(formik.values.jawaban[index], i);

                            return (
                              <Center
                                key={i}
                                p={2}
                                w={"40px"}
                                border={"1px solid var(--divider3)"}
                                borderRadius={8}
                                cursor={"pointer"}
                                bg={
                                  formik.values.jawaban[index] === i
                                    ? "p.500"
                                    : ""
                                }
                                color={
                                  formik.values.jawaban[index] === i
                                    ? selectedScoreColor
                                    : ""
                                }
                                onClick={() => {
                                  handleAnswerClick(index, i);
                                }}
                              >
                                <Text>{label}</Text>
                              </Center>
                            );
                          })}
                        </HStack>
                      </FormControl>
                    ))}
                </form>

                <Button
                  type="submit"
                  form="kuisionerForm"
                  isDisabled={
                    formik.values.jawaban.length !== data?.length ? true : false
                  }
                  colorScheme="ap"
                  className="lg-clicky"
                  isLoading={submitLoading}
                >
                  Kirim
                </Button>

                {formik.values.jawaban.length !== data?.length && (
                  <Text textAlign={"center"} color={errorTextColor}>
                    Kuisioner belum diisi semua
                  </Text>
                )}
              </VStack>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </CustomerContainer>
  );
}
