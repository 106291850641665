import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import { Transaksi } from "../const/types";
import formatDate from "../lib/formatDate";
import DetailTransaksiAdminModal from "./DetailTransaksiAdminModal";
import StatusModal from "./StatusModal";

export default function AdminDashboardsTransaksiTerbaru() {
  const bg = useColorModeValue("white", "dark");

  const [data, setData] = useState<Transaksi[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const url = `api/transaction/latest`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  if (!loading && data) {
    return (
      <Box p={[4, 6, 6]} borderRadius={16} bg={bg} overflow={"auto"}>
        <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
          Transaksi Terbaru
        </Text>

        <Box
          borderRadius={8}
          border={"1px solid var(--divider3)"}
          overflow={"auto"}
          className="scrollX"
        >
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th w={"50px"} isNumeric>
                  No
                </Th>
                <Th>No.Registrasi</Th>
                <Th whiteSpace={"nowrap"}>Tanggal Order</Th>
                <Th>Nama</Th>
                <Th>Produk</Th>
                <Th textAlign={"center"}>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.map((d, i) => (
                <Tr key={i}>
                  <Td isNumeric>{i + 1}</Td>
                  <Td>{d.id.toString().padStart(3, "0")}</Td>
                  <Td whiteSpace={"nowrap"}>{formatDate(d.tgl_transaksi)}</Td>
                  <Td>
                    <Text w={"max-content"}>{d.nama_sertifikat}</Text>
                  </Td>
                  <Td>
                    <Text w={"max-content"}>{d?.product?.name || "-"}</Text>
                  </Td>
                  <Td textAlign={"center"}>
                    <StatusModal data={d} />
                  </Td>
                  <Td isNumeric w={"40px"}>
                    <DetailTransaksiAdminModal noreg={d.id} status={d.status} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
