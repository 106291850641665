import axios from "axios";
import clientRequest from "../const/clientRequest";
import { Transaksi } from "../const/types";

export default function download(
  url: string,
  name: string,
  dataTransaksi: Transaksi
) {
  // const toast = useToast();

  clientRequest.then(() => {
    axios
      .get(url, {
        responseType: "blob",
        withCredentials: true,
        xsrfCookieName: "XSRF-TOKEN",
        xsrfHeaderName: "X-XSRF-TOKEN",
      })
      .then((r) => {
        if (r.status === 200) {
          const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          dataTransaksi
            ? link.setAttribute(
                "download",
                `${name} (${dataTransaksi?.nama_sertifikat}).${r.data.type
                  .split("/")
                  .pop()}`
              )
            : link.setAttribute(
                "download",
                `${name}.${r.data.type.split("/").pop()}`
              );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(downloadUrl);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Gagal mengunduh");
        // toast({
        //   title: "Gagal mengunduh",
        //   description: e?.response?.data?.message,
        //   status: "error",
        //   duration: 10000,
        //   isClosable: true,
        // });
      });
  });
}
