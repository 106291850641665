import React from "react";
import ThreeDotsMenu from "./ThreeDotsMenu";
import { Penguji } from "../const/types";
import UpdatePengujiModal from "./UpdatePengujiModal";
import DeletePengujiModal from "./DeletePengujiModal";

type Props = {
  data: Penguji;
};

export default function AksiUpdateDeletePenguji({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdatePengujiModal data={data} />

      <DeletePengujiModal id={data.id} />
    </ThreeDotsMenu>
  );
}
