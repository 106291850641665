import {
  HStack,
  Icon,
  MenuItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";

export default function AksiDeleteMenuButton(props: any) {
  const buttonBg = useColorModeValue("red.500", "red.200");
  const buttonBgHover = useColorModeValue("red.600", "red.300");
  const buttonBgActive = useColorModeValue("red.700", "red.400");
  const buttonColor = useColorModeValue("white", "black");

  return (
    <MenuItem
      {...props}
      bg={buttonBg}
      color={buttonColor}
      _hover={{ bg: buttonBgHover }}
      _active={{ bg: buttonBgActive }}
    >
      <HStack>
        <Icon as={Trash} fontSize={iconSize} />
        <Text>Hapus</Text>
      </HStack>
    </MenuItem>
  );
}
