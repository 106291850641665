import { StackProps, VStack } from "@chakra-ui/react";
import React from "react";

interface Props extends StackProps {
  children: any;
}

export default function Container({ children, ...props }: Props) {
  return (
    <VStack
      w={props.w || "100%"}
      gap={0}
      maxW={props.maxW || "1280px"}
      mx={"auto"}
      align={"stretch"}
      px={[4, null, 6]}
      {...props}
    >
      {children}
    </VStack>
  );
}
