import { Button, HStack, Text, Link as ChakraLink } from "@chakra-ui/react";
import { LandingNavItemType } from "../const/types";
import useScreenWidth from "../lib/useGetScreenWidth";

type Props = {
  nav: LandingNavItemType;
};

export default function LandingNavItem({ nav }: Props) {
  const sw = useScreenWidth();

  if (sw < 768) {
    return (
      <Button
        variant={"ghost"}
        borderRadius={8}
        // className="btn"
        _hover={{ bg: "whiteAlpha.100" }}
        color={"white"}
        as={ChakraLink}
        href={nav.link}
        justifyContent={"flex-start"}
      >
        <HStack w={"100%"} justify={"space-between"}>
          <Text textAlign={"left"}>{nav.name}</Text>
        </HStack>
      </Button>
    );
  }

  return (
    <Button
      variant={"ghost"}
      borderRadius={"0"}
      // className="btn"
      _hover={{ bg: "whiteAlpha.100" }}
      as={ChakraLink}
      href={nav.link}
      h={"80px"}
      color={"white"}
    >
      {nav.name}
    </Button>
  );
}
