import { Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function TidakAdaData(props: any) {
  return (
    <VStack h={props.h ? props.h : "400px"} justify={"center"} p={6}>
      <Image maxW={"360px"} src={"/vector/noData.webp"} mb={4} />
      <Text fontSize={[16, null, 18]} fontWeight={500}>
        Tidak ada data
      </Text>
    </VStack>
  );
}
