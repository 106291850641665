import React, { useCallback, useState } from "react";
import PromiseSelect from "./PromiseSelect";
import axios from "axios";

type Props = {
  formik: any;
  provinsiId?: string;
};

type Kota = {
  id: string;
  province_id: string;
  name: string;
};

export default function SelectKota({ formik, provinsiId }: Props) {
  const [data, setData] = useState<Kota[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = useCallback(() => {
    setLoading(true);
    const url = `https://rezahawari.github.io/api-wilayah-indonesia/api/regencies/${provinsiId}.json`;
    axios
      .get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [provinsiId]);

  return (
    <PromiseSelect
      name={"kota"}
      formik={formik}
      selectedValue={formik.values.kota?.id}
      placeholder={"Pilih kota/kabupaten"}
      options={data?.map((v) => ({
        value: v.id,
        label: v.name,
        original: v,
      }))}
      loading={loading}
      getData={getData}
      alert={!provinsiId ? "Pilih provinsi terlebih dahulu" : undefined}
      dependencies={provinsiId}
      resetOnSelect={["kecamatan"]}
    />
  );
}
