import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import { Kuisioner } from "../const/types";
import AksiUpdateMenuButton from "./AksiUpdateMenuButton";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

type Props = {
  data: Kuisioner;
};

export default function UpdateKuisionerModal({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      id: data.id,
      pertanyaan: data.pertanyaan,
    },

    validationSchema: yup.object().shape({
      pertanyaan: yup.string().required("Pertanyaan harus diisi"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const url = `api/feedback/quesioner/${data.id}/update`;
      const payload = {
        pertanyaan: values.pertanyaan,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              toast({
                title: "Kuisioner Berhasil Diubah",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              onClose();
              setTrigger(!trigger);
            } else {
              toast({
                title: "Kuisioner Gagal Diubah",
                description:
                  response.data.message.name[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <AksiUpdateMenuButton onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Ubah Pertanyaan</ModalHeader>

          <ModalBody>
            <form id="updateKategoriForm" onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.errors.pertanyaan ? true : false}>
                <FormLabel>
                  Pertanyaan
                  <RequiredForm />
                </FormLabel>
                <Textarea
                  name="pertanyaan"
                  placeholder="Masukkan pertanyaan"
                  onChange={formik.handleChange}
                  value={formik.values.pertanyaan}
                />
                <FormErrorMessage>{formik.errors.pertanyaan}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="updateKategoriForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
