import UpdateKategoriProdukModal from "./UpdateKategoriProdukModal";
import { KategoriProduk } from "../const/types";
import DeleteKategoriProdukModal from "./DeleteKategoriProdukModal";
import ThreeDotsMenu from "./ThreeDotsMenu";

type Props = {
  data: KategoriProduk;
};

export default function AksiUpdateDeleteKategoriProduk({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateKategoriProdukModal data={data} />

      <DeleteKategoriProdukModal id={data.id} />
    </ThreeDotsMenu>
  );
}
