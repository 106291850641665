import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useRef, useState } from "react";
import PasswordInput from "./PasswordInput";
import { useNavigate } from "react-router-dom";
import useBackOnClose from "../lib/useBackOnClose";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { Cookies, setCookie } from "typescript-cookie";
import { UserData } from "../const/types";

export default function SigninModal(props: any) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const modalContentRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      username: "",
      password: "",
      isAdmin: false,
    },

    validationSchema: yup.object().shape({
      username: yup.string().required("Username harus diisi"),
      password: yup.string().required("Kata Sandi harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      const data = {
        username: values.username,
        password: values.password,
      };

      setLoading(true);

      const url = "api/login";
      clientRequest.then(() => {
        reqWithHeader
          .post(url, data)
          .then((response) => {
            if (response.data.status === 200) {
              setCookie("userData", JSON.stringify(response.data.data), {
                expires: 1 / 3,
                path: "/",
              });
              if (values.isAdmin) {
                navigate("/admin");
              } else {
                navigate("/customer");
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data.status === 401) {
              toast({
                title: "Gagal Masuk",
                description: error.response.data.message,
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const handleMasuk = () => {
    const userDataCookie = Cookies.get("userData");
    // console.log(userDataCookie);
    if (userDataCookie) {
      const userData: UserData = JSON.parse(userDataCookie as string);
      if (userData.role === "2") {
        navigate("/admin");
      } else {
        navigate("/customer");
      }
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Button
        id={"signinButton"}
        className="clicky"
        onClick={handleMasuk}
        color={"p.500"}
        bg={"white"}
        colorScheme="white"
        {...props}
      >
        Masuk
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        initialFocusRef={modalContentRef}
        size={"lg"}
      >
        <ModalOverlay />

        <ModalContent ref={modalContentRef}>
          <ModalCloseButton />

          <ModalHeader>
            <HStack justify={"space-between"} align={"flex-start"}>
              <HStack>
                <Image src="/logo192.png" w={"100%"} maxW={"48px"} />

                <Text fontSize={[30, null, 32]} fontWeight={700}>
                  Masuk
                </Text>
              </HStack>
            </HStack>
          </ModalHeader>

          <ModalBody>
            <form id="signinForm" onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={formik.errors.username ? true : false}
                mb={4}
              >
                <FormLabel>Username</FormLabel>
                <Input
                  name="username"
                  placeholder="Masukkan username"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.password ? true : false}
                mb={2}
              >
                <FormLabel>Password</FormLabel>
                <PasswordInput formik={formik} name={"password"} />
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>

              <HStack mb={4}>
                <Text fontWeight={500}>Pegawai</Text>
                <Switch
                  colorScheme="ap"
                  onChange={(e) => {
                    formik.setFieldValue("isAdmin", e.target.checked);
                  }}
                />
              </HStack>

              <Text textAlign={"center"} mb={-1}>
                Lupa password?{" "}
                <span
                  style={{
                    color: "var(--p500)",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleOnClose();
                    const resetPasswordButton = document.querySelector(
                      "#resetPassword"
                    ) as HTMLButtonElement;
                    if (resetPasswordButton) {
                      setTimeout(() => {
                        resetPasswordButton.click();
                      }, 200);
                    }
                  }}
                >
                  Reset
                </span>
              </Text>
            </form>
          </ModalBody>

          <ModalFooter>
            <VStack w={"100%"} gap={0}>
              <Button
                type="submit"
                form="signinForm"
                w={"100%"}
                colorScheme="ap"
                className="lg-clicky btn-ap"
                mb={4}
                isLoading={loading}
              >
                Masuk
              </Button>

              <HStack justify={"center"} gap={1}>
                <Text>Belum Daftar?</Text>
                <Text
                  fontWeight={600}
                  color={"p.500"}
                  cursor={"pointer"}
                  onClick={() => {
                    handleOnClose();
                    const signupButton = document.querySelector(
                      "#signupButton"
                    ) as HTMLButtonElement;
                    if (signupButton) {
                      setTimeout(() => {
                        signupButton.click();
                      }, 200);
                    }
                  }}
                >
                  Daftar
                </Text>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
