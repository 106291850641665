import { create } from "zustand";

type State = {
  selectedFilePendukung: number[];
};

type Actions = {
  setSelectedFilePendukung: (
    selectedFilePendukung: State["selectedFilePendukung"]
  ) => void;
};

const useSelectedFilePendukung = create<State & Actions>((set) => ({
  selectedFilePendukung: [],
  setSelectedFilePendukung: (selectedFilePendukung) =>
    set(() => ({ selectedFilePendukung: selectedFilePendukung })),
}));

export default useSelectedFilePendukung;
