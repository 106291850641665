import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { Transaksi } from "../const/types";
import useSearchTransaksi from "../globalState/useSearchTransaksi";
import useSortTransaksi from "../globalState/useSortTransaksi";
import useTrigger from "../globalState/useTrigger";
import formatDate from "../lib/formatDate";
import formatNumber from "../lib/formatNumber";
import useCompareValues from "../lib/useCompareValues";
import AdminAksiPengujian from "./AdminAksiPengujian";
import ComponentSpinner from "./ComponentSpinner";
import DetailTransaksiAdminModal from "./DetailTransaksiAdminModal";
import PaginationNav from "./PaginationNav";
import StatusModal from "./StatusModal";
import TidakAdaData from "./TidakAdaData";

export default function TabelTransaksi() {
  const [initialData, setInitialData] = useState<Transaksi[] | [] | null>(null);
  const { trigger } = useTrigger();
  const [pagination, setPagination] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { searchTerm } = useSearchTransaksi();
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(searchTimeout);
  }, [searchTerm]);

  useEffect(() => {
    if (search === "") {
      setLoading(true);
      const url = `api/transaction/getall?page=${page}`;
      const payload = { limit: limit };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setInitialData(response.data.data.data);
              setPagination(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [page, limit, trigger, search]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      const url = `api/transaction/search`;
      const payload = { pencarian: search };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.status === 200) {
              setInitialData(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [search]);

  const [data, setData] = useState(initialData ? [...initialData] : null);
  const { sortBy, sortOrder } = useSortTransaksi();
  const compareValues = useCompareValues;
  useEffect(() => {
    if (initialData) {
      const sorted = [...initialData].sort(compareValues(sortBy, sortOrder));
      setData(sorted);
    }
  }, [sortBy, sortOrder, compareValues, initialData]);

  if (!loading && initialData && initialData.length > 0 && data) {
    return (
      <>
        <Box
          borderRadius={8}
          border={"1px solid var(--divider3)"}
          overflow={"auto"}
          className="scrollX scrollY"
          // maxH={sw < 770 ? "calc(100vh - 296px)" : "calc(100vh - 256px)"}
        >
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th isNumeric>No</Th>
                <Th>No.Registrasi</Th>
                <Th whiteSpace={"nowrap"}>Tanggal Order</Th>
                <Th>Nama</Th>
                <Th>Produk</Th>
                <Th textAlign={"center"}>Status</Th>
                <Th textAlign={"center"}>Aksi</Th>
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.map((d, i) => {
                return (
                  <Tr key={i}>
                    <Td isNumeric>
                      {formatNumber(i + 1 + limit * (page - 1))}
                    </Td>
                    <Td whiteSpace={"nowrap"}>{d.kode}</Td>
                    <Td whiteSpace={"nowrap"}>{formatDate(d.tgl_transaksi)}</Td>
                    <Td whiteSpace={"nowrap"}>{d.nama_sertifikat}</Td>
                    <Td whiteSpace={"nowrap"}>
                      <Text>{d.product ? d.product.name : "-"} </Text>
                    </Td>
                    <Td textAlign={"center"}>
                      <StatusModal data={d} />
                    </Td>
                    <Td textAlign={"center"}>
                      <AdminAksiPengujian status={d.status} noreg={d.id} />
                    </Td>
                    <Td isNumeric w={"40px"}>
                      <DetailTransaksiAdminModal
                        noreg={d.id}
                        status={d.status}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>

        <PaginationNav
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          pagination={pagination}
        />
      </>
    );
  } else if (
    !loading &&
    initialData &&
    initialData.length === 0 &&
    data &&
    data.length === 0
  ) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
