import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Container from "../components/Container";
import LandingHeader from "../components/LandingHeader";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import Footer from "../components/Footer";

export default function Policy() {
  return (
    <VStack minH={"100vh"} gap={0}>
      <Box
        id="nav"
        w={"100%"}
        borderBottom={"1px solid var(--divider3)"}
        position={"sticky"}
        top={0}
        bg={"p.500"}
        zIndex={99}
      >
        <Container justify={"center"}>
          <HStack justify={"space-between"} h={["80px", null, null]}>
            <LandingHeader />

            <HStack>
              <ColorModeSwitcher
                color={"white"}
                _hover={{ bg: "whiteAlpha.100 !important" }}
              />
            </HStack>
          </HStack>
        </Container>
      </Box>

      <Container py={[4, null, 6]} maxW={"720px"}>
        <Heading mb={4}>Syarat dan Ketentuan</Heading>

        <Text opacity={0.6} textAlign={"justify"}>
          Pemohon bersedia memberikan informasi dan data yang benar terkait
          kelengkapan administrasi. 

          Pemohon bersedia membayar biaya pengujian
          sesuai dengan tagihan tertampil. 

          Pemohon bersedia mematuhi ketentuan
          alur pengujian dan berada di lokasi saat pengujian berlangsung.
          Apabila pengujian batal akibat informasi yang tidak benar terkait
          dengan kondisi pesawat, pelanggan bersedia mengganti biaya akomodasi
          yang timbul akibat kendala pembatalan tersebut. 
          Pengujian ulang

          dilakukan dengan proses yang sama dengan pengujian baru. Hasil
          pengujian tidak dapat diganggu gugat.
        </Text>
      </Container>

      <VStack bg={"p.500"} w={"100%"} py={8} mt={"auto"}>
        <Container>
          <Footer />
        </Container>
      </VStack>

      <VStack w={"100%"} bg={"p.500"}>
        <Container py={2} borderTop={"1px solid var(--divider)"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={[10, null, 12]}
            opacity={0.5}
          >
            © 2023 powered by{" "}
            <span style={{ fontWeight: 600 }}>Distro Studio</span>
          </Text>
        </Container>
      </VStack>
    </VStack>
  );
}
