import {
  Box,
  Center,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Receipt, TestTube, UsersThree } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import useFormatNumber from "../lib/useFormatNumber";
import useAdminDashboardsPeriodicFilter from "../globalState/useAdminDashboardsPeriodicFilter";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { DashboardSummary } from "../const/types";
import ComponentSpinner from "./ComponentSpinner";
import { ChartLine } from "@phosphor-icons/react/dist/ssr";

export default function DashboardsSummary() {
  const [data, setData] = useState<DashboardSummary | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const bg = useColorModeValue("white", "dark");
  const fn = useFormatNumber;
  const { periode } = useAdminDashboardsPeriodicFilter();

  useEffect(() => {
    setLoading(true);
    const url = `api/transaction/summary/${
      periode === "Bulan"
        ? "monthly"
        : periode === "Minggu"
        ? "weekly"
        : "yearly"
    }`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [periode]);

  // const Rate = ({ data, entity }: { data: any; entity: string }) => {
  //   return (
  //     <Tooltip
  //       label={`${periode} ini ${
  //         data[entity].keterangan === "plus"
  //           ? "naik"
  //           : data[entity].keterangan === "same"
  //           ? "sama"
  //           : "turun"
  //       } ${data[entity].percentage_comparison} dari ${periode} lalu`}
  //       openDelay={500}
  //       bg={
  //         data[entity].keterangan === "plus"
  //           ? "green.500"
  //           : data[entity].keterangan === "same"
  //           ? "black"
  //           : "red.500"
  //       }
  //     >
  //       <Badge
  //         p={2}
  //         borderRadius={8}
  //         bg={
  //           data[entity].keterangan === "plus"
  //             ? "var(--p500a)"
  //             : data[entity].keterangan === "same"
  //             ? "var(--divider)"
  //             : "var(--reda)"
  //         }
  //         fontWeight={400}
  //       >
  //         <HStack>
  //           <Icon
  //             as={
  //               data[entity].keterangan === "plus"
  //                 ? ArrowUp
  //                 : data[entity].keterangan === "same"
  //                 ? Minus
  //                 : ArrowDown
  //             }
  //             fontSize={iconSize}
  //           />
  //           <Text>{data[entity].percentage_comparison}</Text>
  //         </HStack>
  //       </Badge>
  //     </Tooltip>
  //   );
  // };

  if (!loading && data) {
    return (
      <SimpleGrid columns={[1, 2, null, null, null, 4]} gap={4} w={"100%"}>
        <Box borderRadius={16} p={[4, 6, 6]} bg={bg}>
          <HStack mb={4} gap={3} justify={"space-between"}>
            <Center borderRadius={8} p={2} bg={"var(--divider2)"}>
              <Icon as={UsersThree} weight="fill" fontSize={[22, null, 24]} />
            </Center>
          </HStack>

          <Text fontWeight={500} mb={4}>
            Total Customer
          </Text>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            {data.customer}
          </Text>
        </Box>

        <Box borderRadius={16} p={[4, 6, 6]} bg={bg}>
          <HStack mb={4} gap={3} justify={"space-between"}>
            <Center borderRadius={8} p={2} bg={"var(--divider2)"}>
              <Icon as={ChartLine} weight="fill" fontSize={[22, null, 24]} />
            </Center>

            {/* <Rate data={data} entity={"income"} /> */}
          </HStack>

          <Text fontWeight={500} mb={4}>
            Total Pemasukan
          </Text>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            Rp {fn(data.income.total_income)}
          </Text>
        </Box>

        <Box borderRadius={16} p={[4, 6, 6]} bg={bg}>
          <HStack mb={4} gap={3} justify={"space-between"}>
            <Center borderRadius={8} p={2} bg={"var(--divider2)"}>
              <Icon as={TestTube} weight="fill" fontSize={[22, null, 24]} />
            </Center>

            {/* <Rate data={data} entity={"pengujian"} /> */}
          </HStack>

          <Text fontWeight={500} mb={4}>
            Total Pengujian
          </Text>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            {data.pengujian.total_pengujian}
          </Text>
        </Box>

        <Box borderRadius={16} p={[4, 6, 6]} bg={bg}>
          <HStack mb={4} gap={3} justify={"space-between"}>
            <Center borderRadius={8} p={2} bg={"var(--divider2)"}>
              <Icon as={Receipt} weight="fill" fontSize={[22, null, 24]} />
            </Center>

            {/* <Rate data={data} entity={"transaction"} /> */}
          </HStack>

          <Text fontWeight={500} mb={4}>
            Total Transaksi
          </Text>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            {data.transaction.total_transaction}
          </Text>
        </Box>
      </SimpleGrid>
    );
  } else {
    return <ComponentSpinner h={"180px"} />;
  }
}
