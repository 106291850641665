import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PasswordInput from "../components/PasswordInput";
import { useFormik } from "formik";
import * as yup from "yup";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/Container";

export default function InputNewPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { resettoken } = useParams();

  useEffect(() => {
    if (resettoken) {
      setLoading(true);
      const url = `api/reset-password/checktoken`;
      const payload = {
        token: resettoken,
      };

      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((r) => {})
          .catch((e) => {
            console.log(e);
            toast({
              title: "Reset Password Gagal",
              description: e.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
            // navigate("/");
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [navigate, toast, resettoken]);

  const [resetLoading, setResetLoading] = useState<boolean>(false);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: yup.object().shape({
      password: yup.string().required("Kata Sandi harus diisi"),
      confirmPassword: yup
        .string()
        .oneOf(
          [yup.ref("password")],
          "Konfirmasi Password harus cocok dengan Password"
        )
        .required("Konfirmasi Password harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      const payload = {
        token: resettoken,
        password: values.password,
        password_confirmation: values.confirmPassword,
      };

      setResetLoading(true);

      const url = "api/reset-password/confirmpassword";
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.status === 200) {
              toast({
                title: response?.data?.message,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              navigate("/");
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Reset Password Gagal",
              description: error.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setResetLoading(false);
          });
      });
    },
  });

  return (
    <VStack p={5} minH={"100vh"} justify={"center"}>
      {!loading ? (
        <Container>
          <VStack
            gap={4}
            w={"100%"}
            maxW={"360px"}
            align={"stretch"}
            mx={"auto"}
          >
            <HStack justify={"space-between"} align={"flex-start"}>
              <HStack>
                <Image src="/logo192.png" w={"100%"} maxW={"48px"} />

                <Text fontSize={[30, null, 32]} fontWeight={700}>
                  Password Baru
                </Text>
              </HStack>
            </HStack>

            <form id="resetPasswordForm" onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={formik.errors.password ? true : false}
                mb={4}
              >
                <FormLabel>Password </FormLabel>
                <PasswordInput
                  formik={formik}
                  name={"password"}
                  placeholder={"Masukkan password"}
                />
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.confirmPassword ? true : false}
                mb={2}
              >
                <FormLabel>Konfirmasi Password</FormLabel>
                <PasswordInput
                  formik={formik}
                  name={"confirmPassword"}
                  placeholder={"Masukkan ulang password"}
                />
                <FormErrorMessage>
                  {formik.errors.confirmPassword}
                </FormErrorMessage>
              </FormControl>
            </form>

            <Button
              type="submit"
              form="resetPasswordForm"
              colorScheme="ap"
              className="btn-ap lg-clicky"
              isLoading={resetLoading}
            >
              Simpan & Lanjutkan
            </Button>
          </VStack>
        </Container>
      ) : (
        <Spinner />
      )}
    </VStack>
  );
}
