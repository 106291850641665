import React from "react";
import ThreeDotsMenu from "./ThreeDotsMenu";
import { Kuisioner } from "../const/types";
import UpdateKuisionerModal from "./UpdateKuisionerModal";
import DeleteKuisionerModal from "./DeleteKuisionerModal";

type Props = {
  data: Kuisioner;
};

export default function AksiUpdateDeleteKuisioner({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateKuisionerModal data={data} />

      <DeleteKuisionerModal id={data.id} />
    </ThreeDotsMenu>
  );
}
