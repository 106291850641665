import { Center, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function SedangKeluar() {
  const bgKeluar = useColorModeValue("white", "dark");

  return (
    <Center
      bg={"#5b5b5b50"}
      h={"100vh"}
      w={"100%"}
      position={"fixed"}
      top={0}
      left={0}
      zIndex={999}
    >
      <Center
        bg={bgKeluar}
        borderRadius={8}
        p={8}
        pb={6}
        flexDir={"column"}
        gap={2}
      >
        <Spinner />
        <Text textAlign={"center"} fontWeight={500}>
          Sedang Keluar
        </Text>
      </Center>
    </Center>
  );
}
