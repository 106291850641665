import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import clientRequest from "../const/clientRequest";
import axios from "axios";

enum Periode {
  mingguan = "mingguan",
  bulanan = "bulanan",
  tahunan = "tahunan",
  semua = "semua",
}

type Url = {
  [key in Periode]: string; // Menggunakan enum Periode sebagai tipe kunci
};

export default function ExportAllTransaksi() {
  const url: Url = {
    mingguan: "api/transaction/download/weekly",
    bulanan: "api/transaction/download/monthly",
    tahunan: "api/transaction/download/yearly",
    semua: "api/transaction/download/all",
  };
  const toast = useToast();

  const exportKuisioner = (periode: Periode) => {
    clientRequest.then(() => {
      axios
        .get(process.env.REACT_APP_BASE_URL + url[periode], {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `Data Transaksi ${periode}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal export",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        flexShrink={0}
        rightIcon={<Icon as={CaretDown} fontSize={iconSize} />}
        colorScheme="ap"
        className="btn-ap"
      >
        Export
      </MenuButton>

      <MenuList>
        <MenuItem
          onClick={() => {
            exportKuisioner(Periode.mingguan);
          }}
        >
          Export Mingguan
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportKuisioner(Periode.bulanan);
          }}
        >
          Export Bulanan
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportKuisioner(Periode.tahunan);
          }}
        >
          Export Tahunan
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportKuisioner(Periode.semua);
          }}
        >
          Export Semua
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
