import React from "react";
import { Box, HStack, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import SortTransaksi from "../../components/SortTransaksi";
import TabelTransaksi from "../../components/TabelTransaksi";
import SearchTransaksi from "../../components/SearchTransaksi";
import ExportAllTransaksi from "../../components/ExportAllTransaksi";

export default function AdminTransaksi() {
  // useScrollToTop();
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <SimpleGrid mb={4} gap={4} columns={[1, 2]}>
        <SearchTransaksi />

        <HStack justify={["flex-start", "flex-end"]}>
          <SortTransaksi />

          <ExportAllTransaksi />
        </HStack>
      </SimpleGrid>

      <TabelTransaksi />
    </Box>
  );
}
