import { Box, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FotoSampel } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";

export default function DetailFotoSampel() {
  const [data, setData] = useState<FotoSampel[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/berkasutama`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg]);

  if (!loading && data) {
    return (
      <Box>
        <Text fontWeight={600} mb={4}>
          Foto Sampel
        </Text>

        <SimpleGrid columns={[2, 3, 4]} gap={4}>
          {data.map((d, i) => (
            <VStack key={i}>
              <Image
                borderRadius={8}
                boxSize={"100%"}
                objectFit={"cover"}
                src={`${process.env.REACT_APP_VPS_DOMAIN}${d.path}`}
              />
              <Text noOfLines={1} flexShrink={0}>
                {d.name}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
