import { Box, Text, VStack } from "@chakra-ui/react";
import Container from "../components/Container";
import LandingNav from "../components/LandingNav";
import Footer from "../components/Footer";
import ResetPasswordModal from "../components/ResetPasswordModal";
import SignupModal from "../components/SignupModal";
import SigninModal from "../components/SigninModal";
import FloatingContact from "../components/FloatingContact";
import FaqsList from "../components/FaqsList";

export default function Faqs() {
  return (
    <VStack gap={0} minH={"100vh"}>
      <FloatingContact />

      <Box
        id="nav"
        w={"100%"}
        borderBottom={"1px solid var(--divider3)"}
        position={"sticky"}
        top={0}
        bg={"p.500"}
        zIndex={99}
      >
        <Container justify={"center"}>
          <LandingNav />

          <ResetPasswordModal />

          <SigninModal display={"none"} />
          <SignupModal display={"none"} />
        </Container>
      </Box>

      <Container id="faqs" py={12} flex={1}>
        <Text
          fontSize={[22, null, 24]}
          fontWeight={600}
          textAlign={"center"}
          mb={4}
          as={"h2"}
        >
          FREQUENTLY ASKED QUESTION
        </Text>
        <Text textAlign={"center"} mb={6}>
          Tingkatkan Pengalamanmu! Temukan Jawabanmu di FAQ Kami Sekarang!
        </Text>

        <FaqsList />
      </Container>

      <VStack bg={"p.500"} w={"100%"} py={8}>
        <Container>
          <Footer />
        </Container>
      </VStack>

      <VStack w={"100%"} bg={"p.500"}>
        <Container py={2} borderTop={"1px solid var(--divider)"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={[10, null, 12]}
            opacity={0.5}
          >
            © 2023 powered by{" "}
            <span style={{ fontWeight: 600 }}>Distro Studio</span>
          </Text>
        </Container>
      </VStack>
    </VStack>
  );
}
