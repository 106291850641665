import { Box, HStack, Stack, Text, Tooltip } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { UserData } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";

export default function InformasiAkun() {
  const [data, setData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const url = "api/user/logindata";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  if (!loading && data) {
    return (
      <Box>
        <Stack flexDir={["column", "row"]} mb={2} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Nama
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Text>{data?.name}</Text>
          </HStack>
        </Stack>

        <Stack flexDir={["column", "row"]} mb={2} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Kategori
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Text>{data?.jenis}</Text>
          </HStack>
        </Stack>

        <Stack flexDir={["column", "row"]} mb={2} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Telepon
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Text>{data?.hp || "-"}</Text>
          </HStack>
        </Stack>

        <Stack flexDir={["column", "row"]} mb={2} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Email
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Tooltip>
              <Text noOfLines={1}>{data?.email || "-"}</Text>
            </Tooltip>
          </HStack>
        </Stack>

        <Stack flexDir={["column", "row"]} mb={2} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Instansi
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Text>{data?.institusi || "-"}</Text>
          </HStack>
        </Stack>

        <Stack flexDir={["column", "row"]} align={"flex-start"} gap={0}>
          <Text w={"130px"} flexShrink={0} opacity={[0.5, 1]}>
            Alamat Instansi
          </Text>

          <HStack>
            <Text mx={1} display={["none", "block"]}>
              :
            </Text>

            <Text>{data?.alamat_institusi || "-"}</Text>
          </HStack>
        </Stack>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
