import { create } from "zustand";

type State = {
  selectedFotoSampel: number[];
};

type Actions = {
  setSelectedFotoSampel: (
    selectedFotoSampel: State["selectedFotoSampel"]
  ) => void;
};

const useSelectedFotoSampel = create<State & Actions>((set) => ({
  selectedFotoSampel: [],
  setSelectedFotoSampel: (selectedFotoSampel) =>
    set(() => ({ selectedFotoSampel: selectedFotoSampel })),
}));

export default useSelectedFotoSampel;
