import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import useBackOnClose from "../lib/useBackOnClose";
import useSelectedFotoSampel from "../globalState/useSelectedFotoSampel";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useUnggahBerkasTriggerGetFotoSampel from "../globalState/useTrigger";

export default function DeleteFotoSampelModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const { trigger, setTrigger } = useUnggahBerkasTriggerGetFotoSampel();
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedFotoSampel, setSelectedFotoSampel } = useSelectedFotoSampel();
  const onDeleteFotoSampel = () => {
    setLoading(true);
    const url = `api/berkasutama/destroy`;
    const data = {
      id: JSON.stringify(selectedFotoSampel),
    };
    console.log(data);
    clientRequest.then(() => {
      reqWithHeader
        .post(url, data)
        .then((response) => {
          if (response.data.status === 200) {
            setSelectedFotoSampel([]);
            setTrigger(!trigger);
            handleOnClose();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <Button
        aria-label="deleteButton"
        leftIcon={<Icon as={Trash} fontSize={iconSize} />}
        variant={"outline"}
        colorScheme="red"
        className="lg-clicky"
        pl={3}
        onClick={onOpen}
        isDisabled={selectedFotoSampel.length > 0 ? false : true}
      >
        Hapus
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Hapus Foto Sampel</ModalHeader>
          <ModalBody>
            Anda yakin akan menghapus Foto Sampel yang terpilih?
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              colorScheme="red"
              variant={"outline"}
              onClick={handleOnClose}
            >
              Tidak
            </Button>
            <Button
              isLoading={loading}
              colorScheme="red"
              onClick={onDeleteFotoSampel}
            >
              Yakin
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
