import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import landingNav from "../const/landingNav";
import { List } from "@phosphor-icons/react/dist/ssr";
import { iconSize } from "../const/sizes";
import LandingNavItem from "./LandingNavItem";
import SigninModal from "./SigninModal";
import useBackOnClose from "../lib/useBackOnClose";
import SignupModal from "./SignupModal";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

export default function LandingNavDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useBackOnClose(isOpen, onClose);

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  return (
    <>
      <IconButton
        aria-label="menu"
        icon={<Icon as={List} fontSize={iconSize} />}
        variant={"outline"}
        colorScheme="whiteAlpha"
        color={"white !important"}
        onClick={onOpen}
        zIndex={2}
      />

      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />

        <DrawerContent>
          <DrawerBody
            as={VStack}
            align={"stretch"}
            p={0}
            position={"relative"}
            bg={"p.600"}
            py={5}
          >
            <Image
              src={"/images/navDrawerBg.png"}
              w={"100%"}
              position={"absolute"}
              bottom={0}
              opacity={0.1}
            />

            <VStack gap={0} position={"relative"}>
              <HStack mt={2}>
                <ColorModeSwitcher
                  position={"absolute"}
                  left={0}
                  fontSize={20}
                />

                <DrawerCloseButton color={"white"} top={-2} right={4} />
              </HStack>

              <Image src="/images/logoWhite.png" w={"200px"} mb={2} mt={4} />
            </VStack>

            <VStack
              w={"100%"}
              flex={1}
              py={6}
              px={10}
              align={"stretch"}
              justify={"space-between"}
            >
              <VStack w={"100%"} align={"stretch"}>
                <Text
                  fontWeight={500}
                  opacity={0.5}
                  ml={1}
                  fontSize={[12, null, 14]}
                >
                  NAVIGASI
                </Text>
                {landingNav.map((n, i) => (
                  <VStack key={i} align={"stretch"} onClick={onClose}>
                    <LandingNavItem nav={n} />
                  </VStack>
                ))}
              </VStack>

              <VStack align={"stretch"}>
                <SigninModal />

                <SignupModal />
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
