import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CaretDown, CaretLeft, CaretRight } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";
import PaginationJump from "./PaginationJump";

type Props = {
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  pagination: any;
};

export default function PaginationNav({
  limit,
  setLimit,
  page,
  setPage,
  pagination,
}: Props) {
  // props setLimit, page, setPage, pagination

  return (
    <Stack
      flexDir={"row"}
      justify={"space-between"}
      align={"center"}
      mt={4}
      gap={4}
    >
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={CaretDown} fontSize={iconSize} />}
          className="btn-solid"
          minW={"135px"}
        >
          <HStack>
            <Text>Show</Text>
            <Text color={"p.500"} fontWeight={600}>
              {limit}
            </Text>
          </HStack>
        </MenuButton>

        <MenuList minW={"135px"}>
          <MenuItem
            onClick={() => {
              setLimit(10);
              setPage(1);
            }}
          >
            10
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLimit(50);
              setPage(1);
            }}
          >
            50
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLimit(100);
              setPage(1);
            }}
          >
            100
          </MenuItem>
        </MenuList>
      </Menu>

      <HStack justify={"center"}>
        <IconButton
          aria-label="prev"
          icon={<Icon as={CaretLeft} fontSize={iconSize} />}
          variant={"ghost"}
          className="btn"
          size={"sm"}
          onClick={() => {
            setPage(page - 1);
          }}
          isDisabled={!pagination.prev_page_url}
        />

        <PaginationJump page={page} setPage={setPage} pagination={pagination} />

        <IconButton
          aria-label="prev"
          icon={<Icon as={CaretRight} fontSize={iconSize} />}
          variant={"ghost"}
          className="btn"
          size={"sm"}
          onClick={() => {
            setPage(page + 1);
          }}
          isDisabled={!pagination.next_page_url}
        />
      </HStack>
    </Stack>
  );
}
