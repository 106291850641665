import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import { PencilSimpleLine } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import useFormatNumber from "../lib/useFormatNumber";
import useReverseFormatNumber from "../lib/useReverseFormatNumber";
import { KategoriProduk, Product } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import useTrigger from "../globalState/useTrigger";

type Props = {
  data: Product;
};

export default function UpdateProdukModal({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [kategori, setKategori] = useState<KategoriProduk[] | null>(null);
  const [loadingKategori, setLoadingKategori] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (isOpen && !kategori) {
      setLoadingKategori(true);
      const url = `api/product/kategori`;
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.data.status === 200) {
              setKategori(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Terjadi Kesalahan Silahkan Refresh",
              description: "Maaf terjadi kesalahan sistem",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingKategori(false);
          });
      });
    }
  }, [isOpen, kategori, toast]);

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      id: data.id,
      kode: data.kode,
      namaProduk: data.name,
      kategori_id: data.kategori_id,
      harga: data.price,
      deskripsi: data.deskripsi,
    },

    validationSchema: yup.object().shape({
      kode: yup.string().required("Kode Produk harus diisi"),
      namaProduk: yup.string().required("Nama Produk harus diisi"),
      kategori_id: yup.string().required("Kategori Produk harus diisi"),
      harga: yup
        .number()
        .min(1, "Harga Produk harus lebih dari 0")
        .required("Harga harus diisi"),
      deskripsi: yup.string().required("Deskripsi harus diisi"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const url = `api/product/${data.id}/update`;
      const payload = {
        kode: values.kode,
        name: values.namaProduk,
        kategori_id: values.kategori_id,
        price: values.harga,
        deskripsi: values.deskripsi,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setTrigger(!trigger);
              toast({
                title: "Produk Berhasil Diubah",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Produk Gagal Diubah",
                description:
                  response.data.message.name[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const fn = useFormatNumber;
  const rfn = useReverseFormatNumber;

  return (
    <>
      <MenuItem onClick={onOpen}>
        <HStack>
          <Icon as={PencilSimpleLine} fontSize={iconSize} />
          <Text>Ubah</Text>
        </HStack>
      </MenuItem>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Ubah Produk</ModalHeader>

          {!loadingKategori && (
            <>
              <ModalBody>
                <form id="updateForm" onSubmit={formik.handleSubmit}>
                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.kode ? true : false}
                  >
                    <FormLabel>
                      Kode
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="kode"
                      placeholder="Masukkan kode produk"
                      onChange={formik.handleChange}
                      value={formik.values.kode}
                    />
                    <FormErrorMessage>{formik.errors.kode}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.namaProduk ? true : false}
                  >
                    <FormLabel>
                      Nama Produk
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="namaProduk"
                      placeholder="Masukkan nama produk"
                      onChange={formik.handleChange}
                      value={formik.values.namaProduk}
                    />
                    <FormErrorMessage>
                      {formik.errors.namaProduk}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.kategori_id ? true : false}
                  >
                    <FormLabel>
                      Kategori Produk
                      <RequiredForm />
                    </FormLabel>
                    <Select
                      name="kategori_id"
                      placeholder="Pilih kategori produk"
                      onChange={formik.handleChange}
                      value={formik.values.kategori_id}
                    >
                      {kategori?.map((k, i) => (
                        <option key={i} value={k.id}>
                          {k.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {formik.errors.kategori_id}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.harga ? true : false}
                  >
                    <FormLabel>
                      Harga
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="harga"
                      placeholder="Masukkan harga produk"
                      onChange={(e) => {
                        formik.setFieldValue("harga", rfn(e.target.value));
                      }}
                      value={fn(formik.values.harga)}
                    />
                    <FormErrorMessage>{formik.errors.harga}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={formik.errors.deskripsi ? true : false}
                  >
                    <FormLabel>
                      Deskripsi Produk
                      <RequiredForm />
                    </FormLabel>
                    <Textarea
                      name="deskripsi"
                      placeholder="Masukkan deskripsi produk"
                      onChange={formik.handleChange}
                      value={formik.values.deskripsi}
                    />
                    <FormErrorMessage>
                      {formik.errors.deskripsi}
                    </FormErrorMessage>
                  </FormControl>
                </form>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  form="updateForm"
                  colorScheme="ap"
                  className="lg-clicky"
                  isLoading={loading}
                >
                  Simpan
                </Button>
              </ModalFooter>
            </>
          )}

          {loadingKategori && <ComponentSpinner h={"calc(376px + 88px)"} />}
        </ModalContent>
      </Modal>
    </>
  );
}
