import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import * as yup from "yup";
import { useFormik } from "formik";
import RequiredForm from "./RequiredForm";
import FilesInput from "./FilesInput";
import useBackOnClose from "../lib/useBackOnClose";
import { useParams } from "react-router-dom";
import useTrigger from "../globalState/useTrigger";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

export default function TambahFilePendukungModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      nama: "",
      filePendukung: [] as File[] | null,
      keterangan: "",
    },

    validationSchema: yup.object().shape({
      nama: yup.string().required("Nama File Pendukung Harus diisi"),
      filePendukung: yup
        .array()
        .min(1, "File Pendukung harus diunggah")
        .required("File Pendukung harus diunggah minimal 1")
        .test("fileType", "Hanya file PDF yang diperbolehkan", (value) =>
          value.every((file) => {
            const allowedTypes = [
              "application/pdf",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            return allowedTypes.includes(file.type);
          })
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      keterangan: yup.string(),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      const url = `api/transaksi/${noreg}/berkaspendukung`;
      const data = new FormData();
      data.append("name", values.nama);
      if (values.filePendukung) {
        values.filePendukung.forEach((file) => {
          data.append("file", file);
        });
      }

      data.append("keterangan", values.keterangan);
      const params = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      clientRequest.then(() => {
        reqWithHeader
          .post(url, data, params)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
              toast({
                title: "File Pendukung Berhasil Ditambahkan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              resetForm();
              setTrigger(!trigger);
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "File Pendukung Gagal Ditambahkan",
              description: "Maaf terjadi kesalahan",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        leftIcon={<Icon as={Plus} fontSize={iconSize} weight="bold" />}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
        pl={"12px"}
      >
        Tambah File Pendukung
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Masukkan File Pendukung</ModalHeader>

          <ModalBody>
            <Alert
              status="warning"
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <AlertIcon mt={"2px"} />
              <Box>
                <AlertTitle>
                  <Text
                    fontSize={[18, null, 20]}
                    fontWeight={600}
                    color={useColorModeValue("orange.500", "orange.200")}
                  >
                    PERHATIAN!!
                  </Text>
                </AlertTitle>
                <AlertDescription>
                  Format berkas pendukung berupa <b>PDF</b>, <b>maks. 1 MB</b>
                </AlertDescription>
              </Box>
            </Alert>

            <form id="filePendukungForm" onSubmit={formik.handleSubmit}>
              <FormControl mb={4} isInvalid={formik.errors.nama ? true : false}>
                <FormLabel>
                  Nama File Pendukung
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="nama"
                  onChange={formik.handleChange}
                  placeholder="Masukkan nama file pendukung"
                  value={formik.values.nama}
                />
                <FormErrorMessage>{formik.errors.nama}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.filePendukung ? true : false}
                mb={4}
              >
                <FormLabel>
                  Unggah File Pendukung
                  <RequiredForm />
                </FormLabel>
                <FilesInput
                  formik={formik}
                  name={"filePendukung"}
                  accept={".pdf"}
                />
                <FormErrorMessage>
                  {formik.errors.filePendukung}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.keterangan ? true : false}>
                <FormLabel>Keterangan</FormLabel>
                <Textarea
                  name="keterangan"
                  onChange={formik.handleChange}
                  placeholder="Masukkan catatan anda"
                />
                <FormErrorMessage>{formik.errors.keterangan}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="filePendukungForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Simpan & Lanjutkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
