import React from "react";
import CustomerContainer from "../../components/CustomerContainer";
import Container from "../../components/Container";
import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import InformasiAkun from "../../components/InformasiPelanggan";
import CustomerStats from "../../components/CustomerStats";
import customerMenus from "../../const/customerMenus";
import { Link } from "react-router-dom";
import useScreenWidth from "../../lib/useGetScreenWidth";

export default function Beranda() {
  const sw = useScreenWidth();

  return (
    <CustomerContainer active={"Beranda"}>
      <>
        <VStack
          w={"100%"}
          gap={0}
          bg={useColorModeValue("var(--divider)", "#1c1c1c")}
        >
          <Container>
            <SimpleGrid columns={[1, null, 1, 2]} py={12} gap={8}>
              <Box>
                <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                  Informasi Akun
                </Text>

                <InformasiAkun />
              </Box>

              <Box>
                <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                  Statistik Transaksi
                </Text>

                <CustomerStats />
              </Box>
            </SimpleGrid>
          </Container>
        </VStack>

        <Container py={12}>
          <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
            Menu
          </Text>

          <SimpleGrid columns={sw < 660 ? 1 : sw < 900 ? 2 : 3} gap={4}>
            {customerMenus.map((menu, i) => (
              <HStack
                gap={0}
                key={i}
                p={4}
                pl={0}
                borderRadius={16}
                bg={"p.500"}
                as={Link}
                to={menu.link}
                className="lg-clicky"
                transition={"200ms"}
                position={"relative"}
                justify={"flex-end"}
                overflow={"clip"}
              >
                <Image src={menu.img} h={"120px"} flexShrink={1} ml={-4} />

                <Text
                  fontSize={[20, null, 22]}
                  fontWeight={600}
                  color={"white"}
                  w={"100%"}
                  lineHeight={1.4}
                  px={4}
                  flexGrow={1}
                >
                  {menu.name}
                </Text>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </>
    </CustomerContainer>
  );
}
