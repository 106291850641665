import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

export default function ResetPasswordModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const emailTextHelperColor = useColorModeValue("red.500", "red.200");

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      email: "",
    },

    validationSchema: yup.object().shape({
      email: yup.string().required("Email harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      const url = `api/reset-password/checkemail`;
      const payload = {
        email: values.email,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              console.log(response.data.data);
              toast({
                title: response.data.message,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            toast({
              title: e.response.data.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <span
        id="resetPassword"
        style={{
          color: "var(--p500)",
          fontWeight: 600,
          cursor: "pointer",
          display: "none",
        }}
        onClick={onOpen}
      >
        Reset
      </span>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            <HStack justify={"space-between"} align={"flex-start"}>
              <HStack align={"flex-start"}>
                <Image src="/logo192.png" w={"100%"} maxW={"48px"} />

                <Text
                  mt={1}
                  fontSize={[30, null, 32]}
                  fontWeight={700}
                  lineHeight={1.2}
                >
                  Lupa Kata Sandi
                </Text>
              </HStack>
            </HStack>
          </ModalHeader>

          <ModalBody>
            <Alert
              className="alert"
              variant={"left-accent"}
              colorScheme="orange"
              mb={6}
            >
              <Box>
                <AlertTitle
                  fontSize={[18, null, 20]}
                  color={useColorModeValue("orange.500", "orange.200")}
                >
                  PERHATIAN!!
                </AlertTitle>
                <AlertDescription>
                  Kami akan mengirimkan{" "}
                  <b>
                    tautan reset kata sandi pada email yang anda masukkan di
                    bawah ini
                  </b>
                  , mohon untuk <b>tidak memberikan</b> tautan tersebut pada
                  orang lain,{" "}
                  <b>anda bertanggung jawab penuh atas tautan ini</b>
                </AlertDescription>
              </Box>
            </Alert>

            <form id="lupaKataSandiForm" onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.errors.email ? true : false}>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  placeholder="Masukkan email"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                <FormHelperText color={emailTextHelperColor}>
                  *tautan reset kata sandi akan dikirimkan melalui email
                </FormHelperText>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="lupaKataSandiForm"
              w={"100%"}
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Kirim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
