import React from "react";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import TabelPenguji from "../../components/TabelPenguji";
import TambahPengujiModal from "../../components/TambahPengujiModal";
import SortPenguji from "../../components/SortPenguji";
import SearchPenguji from "../../components/SearchPenguji";

export default function AdminCustomer() {
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <HStack mb={4}>
        <SearchPenguji />

        <SortPenguji />

        <TambahPengujiModal />
      </HStack>

      <TabelPenguji />
    </Box>
  );
}
