import {
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
  Link as ChakraLink,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { WhatsappLogo, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { KontakWa } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

export default function FloatingContact() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue("white !important", "dark !important");
  const [data, setData] = useState<KontakWa[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const url = `api/contact/get`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const floatingItems = document.querySelectorAll(".floatingitems");

      if (!floatingItems.length) {
        onClose();
        return;
      }

      let clickedInside = false;
      floatingItems.forEach((item) => {
        if (item && item.contains(event.target as Node)) {
          clickedInside = true;
        }
      });

      if (!clickedInside) {
        onClose();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  return (
    <VStack
      align={"flex-end"}
      position={"fixed"}
      bottom={4}
      right={4}
      zIndex={99}
      gap={0}
      pl={4}
    >
      <VStack
        align={"flex-end"}
        display={isOpen ? "flex" : "none"}
        animation={"fade-in 500ms"}
      >
        {loading && (
          <HStack
            mb={1}
            border={"1px solid var(--divider3)"}
            bg={bg}
            borderRadius={"20px 20px 0 20px"}
            py={2}
            px={4}
            mr={"60px"}
            className="floatingitems"
          >
            <Spinner size={"sm"} />
          </HStack>
        )}

        {!loading &&
          data &&
          data.length > 0 &&
          data.map((kontak, i) => (
            <HStack
              key={i}
              mb={1}
              border={"1px solid var(--divider3)"}
              bg={bg}
              borderRadius={"20px 20px 0 20px"}
              py={2}
              px={4}
              mr={"60px"}
              as={ChakraLink}
              href={`https://wa.me/${kontak.wa}`}
              isExternal
              _hover={{ color: "var(--p500)" }}
              className="floatingitems"
            >
              <Text fontWeight={500}> {`${kontak.wa} (${kontak.name})`}</Text>
            </HStack>
          ))}
      </VStack>

      <HStack>
        <HStack
          position={"relative"}
          bg={bg}
          borderRadius={"20px 20px 0 20px"}
          py={1}
          px={6}
          // mb={8}
          border={"1px solid var(--divider3)"}
          className="floatingitems"
        >
          <Text opacity={isOpen ? 0.5 : 1}>
            {isOpen ? "Tutup" : "Butuh bantuan?"}
          </Text>
        </HStack>

        <IconButton
          aria-label={"contact"}
          icon={<Icon as={isOpen ? X : WhatsappLogo} fontSize={28} />}
          borderRadius={"full"}
          className="clicky"
          _hover={{ bg: bg }}
          bg={bg}
          border={"1px solid var(--divider3)"}
          color={"p.500"}
          size={"lg"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            if (isOpen) {
              onClose();
            } else {
              onOpen();
            }
          }}
        />
      </HStack>
    </VStack>
  );
}
