import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import Container from "../components/Container";
import LandingNav from "../components/LandingNav";
import Footer from "../components/Footer";
import ResetPasswordModal from "../components/ResetPasswordModal";
import SignupModal from "../components/SignupModal";
import SigninModal from "../components/SigninModal";
import HeroImages from "../components/HeroImages";
import FloatingContact from "../components/FloatingContact";
import { Link } from "react-router-dom";
import useScreenWidth from "../lib/useGetScreenWidth";
import FaqsList from "../components/FaqsList";

export default function Landing() {
  const sw = useScreenWidth();
  const bidangPengujian = [
    {
      image: "/images/bidang/0.jpg",
      name: "Dental Intra Oral",
      subName: "PESAWAT SINAR-X",
    },
    {
      image: "/images/bidang/1.jpg",
      name: "Radiografi Mobile",
      subName: "PESAWAT SINAR-X",
    },
    {
      image: "/images/bidang/2.jpg",
      name: "Radiografi Umum",
      subName: "PESAWAT SINAR-X",
    },
    {
      image: "/images/bidang/3.jpg",
      name: "Radiografi Panoramic/Panochepalometri",
      subName: "PESAWAT SINAR-X",
    },
  ];
  // const petunjukPraktis = [
  //   {
  //     title: "Register",
  //     desc: "Buat akun dan Lengkapi Profil",
  //   },
  //   {
  //     title: "Pengujian",
  //     desc: "Petugas laboratorium akan melakukan pengujian sesuai prosedur yang telah ditetapkan.",
  //   },
  //   {
  //     title: "Hasil",
  //     desc: "Dapatkan hasil pengujian melalui website kami",
  //   },
  // ];

  //SX
  const wdbg = useColorModeValue("white", "dark");

  return (
    <VStack gap={0} minH={"100vh"}>
      <FloatingContact />

      <Box
        id="nav"
        w={"100%"}
        borderBottom={"1px solid var(--divider3)"}
        position={"sticky"}
        top={0}
        bg={"p.500"}
        zIndex={99}
      >
        <Container justify={"center"}>
          <LandingNav />

          <ResetPasswordModal />

          <SigninModal display={"none"} />
          <SignupModal display={"none"} />
        </Container>
      </Box>

      <Box id="hero" w={"100%"}>
        <HeroImages />

        <Container my={24}>
          <Stack flexDir={["column", null, "row"]} gap={12}>
            <Image
              src="/images/about.png"
              w={["100%", null, "35%"]}
              objectFit={"contain"}
            />

            <Box w={["100%", null, "65%"]} py={8}>
              <Text
                color={"p.500"}
                fontWeight={600}
                mb={2}
                fontSize={[10, null, 12]}
                textAlign={["center", null, "left"]}
              >
                TENTANG KAMI
              </Text>

              <Text
                fontSize={24}
                fontWeight={700}
                mb={8}
                textAlign={["center", null, "left"]}
              >
                POLTEKKES KEMENKES SEMARANG
              </Text>

              <Text mb={4} lineHeight={"187.5%"} textAlign={"justify"}>
                Kami melayani pengujian untuk Laboratorium Radiologi. Bidang
                pengujian yang dilayani oleh Poltekkes Kemenkes Semarang
                meliputi bidang fisika. Sampel yang dapat dilakukan pengujian di
                Poltekkes Kemenkes Semarang meliputi pesawat radiologi umum,
                pesawat radiologi mobile, dan pesawat radiologi dental di lokasi
                pelanggan (rumah sakit, klinik, atau fasilitas kesehatan
                lainnya).
              </Text>

              <Text lineHeight={"187.5%"} textAlign={"justify"}>
                Metode yang digunakan dikembangkan dari metode Uji Kesesuaian
                Pesawat Radiografi yang diterbitkan oleh Badan Pengawas Tenaga
                Nuklir dengan pengembangan berupa uji HVL yang disesuaikan
                dengan metode uji yang diterbitkan produsen alat.
              </Text>
            </Box>
          </Stack>
        </Container>

        <Container my={24}>
          <Text
            color={"p.500"}
            fontWeight={600}
            textAlign={"center"}
            mb={2}
            fontSize={[10, null, 12]}
          >
            LABORATORIUM
          </Text>
          <Text fontSize={24} fontWeight={700} mb={8} textAlign={"center"}>
            BIDANG PENGUJIAN
          </Text>

          <SimpleGrid columns={[1, 2, 4]} gap={5}>
            {bidangPengujian.map((bidang, i) => (
              <VStack key={i}>
                <Image
                  src={bidang.image}
                  w={"100%"}
                  objectFit={"cover"}
                  h={"200px"}
                  borderRadius={8}
                />

                <Box
                  py={4}
                  px={6}
                  borderRadius={8}
                  boxShadow={"2px 0 5px var(--divider2)"}
                  mt={-8}
                  bg={wdbg}
                  w={"90%"}
                >
                  <Text
                    fontSize={[10, null, 12]}
                    color={"p.500"}
                    fontWeight={600}
                    mb={1}
                  >
                    {bidang.subName}
                  </Text>

                  <Text fontWeight={600} lineHeight={1.4}>
                    {bidang.name}
                  </Text>
                </Box>
              </VStack>
            ))}
          </SimpleGrid>
        </Container>

        {/* <Container my={24}>
          <Text
            color={"p.500"}
            fontWeight={600}
            textAlign={"center"}
            mb={2}
            fontSize={[10, null, 12]}
          >
            WORKFLOW
          </Text>
          <Text fontSize={24} fontWeight={700} mb={"62px"} textAlign={"center"}>
            Petunjuk Praktis dalam Proses Pengujian Laboratorium
          </Text>

          <Stack gap={2} flexDir={["column", null, "row"]} align={"stretch"}>
            {petunjukPraktis.map((petunjuk, i) => (
              <>
                <VStack key={i} flex={"1 1 0"}>
                  <VStack
                    justify={"center"}
                    position={"relative"}
                    bg={"p.500"}
                    borderRadius={24}
                    p={6}
                    pt={"44px"}
                    w={"100%"}
                    h={"100%"}
                  >
                    <Center
                      p={4}
                      borderRadius={"full"}
                      bg={wdbg}
                      border={"1px solid var(--p500)"}
                      h={"60px"}
                      w={"60px"}
                      position={"absolute"}
                      top={"-30px"}
                    >
                      <Text
                        fontWeight={600}
                        fontSize={[18, null, 20]}
                        color={"p.500"}
                      >
                        {i + 1}
                      </Text>
                    </Center>

                    <Text
                      fontSize={20}
                      fontWeight={600}
                      textAlign={"center"}
                      color={"white"}
                    >
                      {petunjuk.title}
                    </Text>
                    <Text textAlign={"center"} color={"white"}>
                      {petunjuk.desc}
                    </Text>
                  </VStack>
                </VStack>

                {i < 2 && (
                  <VStack
                    w={"100%"}
                    maxW={[null, null, "50px"]}
                    justify={"center"}
                    mb={[7, null, 0]}
                  >
                    <VStack
                      h={["70px", null, "1px"]}
                      w={["1px", null, "100%"]}
                      bg={"p.500"}
                      justify={"center"}
                    >
                      <Center
                        p={1}
                        borderRadius={"full"}
                        bg={wdbg}
                        w={"20px"}
                        h={"20px"}
                        border={"1px solid var(--p500)"}
                        color={"p.500"}
                      >
                        <Icon
                          as={CaretRight}
                          transform={["rotate(90deg)", null, ""]}
                        />
                      </Center>
                    </VStack>
                  </VStack>
                )}
              </>
            ))}
          </Stack>
        </Container> */}

        <Container my={24}>
          <Text
            color={"p.500"}
            fontWeight={600}
            textAlign={"center"}
            mb={2}
            fontSize={[10, null, 12]}
          >
            AKREDITASI
          </Text>
          <Text fontSize={24} fontWeight={700} mb={8} textAlign={"center"}>
            KEGIATAN PENGUJIAN
          </Text>

          <Text lineHeight={"187.5%"} mb={6} textAlign={"justify"}>
            Manajemen Poltekkes Kemenkes Semarang mempunyai komitmen pada
            praktek profesional dan mutu pengujian dalam melayani mitra kerja
            dan pelanggan. Selain itu, dalam melaksanakan pengujian Poltekkes
            Kemenkes Semarang mengacu pada sistem manajemen mutu laboratorium
            ISO/IEC 17025:2017, mengutamakan mutu dan kepuasan mitra kerja dan
            pelanggan, serta menjamin pekerjaan pengujian dilakukan dengan
            kejujuran teknis, teliti, akurat, dan terjaga kerahasiaannya.
          </Text>

          <Text lineHeight={"187.5%"} mb={10} textAlign={"justify"}>
            Pada tahun 2023 Poltekkes Kemenkes Semarang memiliki kompetensi
            pengujian yang telah terakreditasi oleh Komite Akreditasi Nasional
            (KAN). Berikut ini merupakan sertifikat akreditasi yang telah
            menunjukkan kompetensi sebagai <b>Laboratorium Penguji</b>{" "}
            LP-1786-IDN yang secara konsisten menerapkan standar SNI ISO/IEC
            17025:2017 (ISO/IEC 17025:2017).
          </Text>

          <SimpleGrid columns={[1, null, 2]} gap={6}>
            <Image src="/images/sertif1.png" />
            <Image src="/images/sertif2.png" />
          </SimpleGrid>
        </Container>
      </Box>

      <Container id="faqs" py={12}>
        <HStack
          flexDir={sw < 450 ? "column" : "row"}
          mb={8}
          justify={"space-between"}
          align={sw < 450 ? "center" : "flex-end"}
          gap={4}
        >
          <Box>
            <Text
              color={"p.500"}
              fontWeight={600}
              mb={2}
              fontSize={[10, null, 12]}
              textAlign={sw < 450 ? "center" : "left"}
            >
              FAQs
            </Text>
            <Text
              fontSize={24}
              fontWeight={700}
              textAlign={sw < 450 ? "center" : "left"}
            >
              FREQUENTLY ASKED QUESTION
            </Text>
          </Box>

          <Text as={Link} to={"/faqs"} fontWeight={500} color={"p.500"}>
            Lihat Semua
          </Text>
        </HStack>

        <FaqsList limit={5} />
      </Container>

      <VStack bg={"p.500"} w={"100%"} py={8}>
        <Container>
          <Footer />
        </Container>
      </VStack>

      <VStack w={"100%"} bg={"p.500"}>
        <Container py={2} borderTop={"1px solid var(--divider)"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={[10, null, 12]}
            opacity={0.5}
          >
            © 2023 powered by{" "}
            <span style={{ fontWeight: 600 }}>Distro Studio</span>
          </Text>
        </Container>
      </VStack>
    </VStack>
  );
}
