const customerNav = [
  {
    name: "Beranda",
    link: "/customer",
  },
  {
    name: "Pengujian",
    link: "/customer/pengujian",
  },
];

export default customerNav;
