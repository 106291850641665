import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import { Penguji } from "../const/types";
import AksiUpdateMenuButton from "./AksiUpdateMenuButton";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

type Props = {
  data: Penguji;
};

export default function UpdatePengujiModal({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      id: data.id,
      nama: data.nama,
      email: data.email,
      no_hp: data.no_hp,
    },

    validationSchema: yup.object().shape({
      nama: yup.string().required("Nama Lengkap harus diisi"),
      email: yup.string().email().required("Email harus diisi"),
      no_hp: yup.string().required("Email harus diisi"),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const url = `api/penguji/${data.id}/update`;
      const payload = {
        name: values.nama,
        email: values.email,
        hp: values.no_hp,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
              toast({
                title: "Penguji Berhasil Diubah",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              setTrigger(!trigger);
              onClose();
            } else {
              toast({
                title: "Penguji Gagal Diubah",
                description:
                  response.data.message.email[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <AksiUpdateMenuButton onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Ubah Produk</ModalHeader>

          <ModalBody>
            <form id="updateForm" onSubmit={formik.handleSubmit}>
              <FormControl mb={4} isInvalid={formik.errors.nama ? true : false}>
                <FormLabel>
                  Nama Lengkap
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="nama"
                  placeholder="Masukkan nama lengkap"
                  onChange={formik.handleChange}
                  value={formik.values.nama}
                />
                <FormErrorMessage>{formik.errors.nama}</FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.email ? true : false}
              >
                <FormLabel>
                  Email
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="email"
                  placeholder="Masukkan email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.no_hp ? true : false}
              >
                <FormLabel>
                  No. Ponsel
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="no_hp"
                  placeholder="Masukkan no_hp"
                  onChange={formik.handleChange}
                  value={formik.values.no_hp}
                />
                <FormErrorMessage>{formik.errors.no_hp}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="updateForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
