import React, { useState } from "react";
import CustomerContainer from "../../components/CustomerContainer";
import Container from "../../components/Container";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Link as ChakraLink,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InformasiAkun from "../../components/InformasiPelanggan";
import StepperPengujian from "../../components/StepperPengujian";
import { CaretRight } from "@phosphor-icons/react";
import DetailMetodeUji from "../../components/DetailMetodeUji";
import DetailFotoSampel from "../../components/DetailFotoSampel";
import DetailFilePendukung from "../../components/DetailFilePendukung";
import DetailSummary from "../../components/DetailSummary";
import { checkBoxTextMt } from "../../const/sizes";
import useScreenWidth from "../../lib/useGetScreenWidth";
import InformasiTransaksi from "../../components/InformasiTransaksi";
import clientRequest from "../../const/clientRequest";
import reqWithHeader from "../../const/reqWithHeader";

export default function DetailPengujian() {
  const [agreement, setAgreement] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sw = useScreenWidth();
  const { noreg } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const saveTransaction = () => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/save`;
    const data = {
      disclaimer: agreement ? "1" : "0",
    };
    clientRequest.then(() => {
      reqWithHeader
        .post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 200) {
            toast({
              title: response.data.message,
              description: `Berkas dengan noreg ${noreg} berhasil disimpan`,
              status: "success",
              duration: 10000,
              isClosable: true,
            });
            navigate("/customer/pengujian");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <CustomerContainer active="Pengujian">
      <Container>
        <Box>
          {/* Bread Crumbs */}
          <HStack className="breadcrumb" mt={6}>
            <Link to="/customer" className="breadcrumbItem">
              Beranda
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Link to="/customer/pengujian" className="breadcrumbItem">
              Pengujian
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Text className="breadcrumbCurrent breadcrumbItem">
              Detail Pengujian
            </Text>
          </HStack>

          <Text fontSize={[22, null, 24]} fontWeight={600} mt={6} mb={6}>
            Pendaftaran Pengujian Online
          </Text>

          <SimpleGrid
            columns={sw < 900 ? 1 : 2}
            gap={6}
            px={[4, 5, 6]}
            pt={[5]}
            pb={[6]}
            bg={"var(--p500a3)"}
            borderRadius={16}
          >
            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Data Pelanggan
              </Text>

              <InformasiAkun />
            </Box>

            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Informasi Transaksi
              </Text>

              <InformasiTransaksi />
            </Box>
          </SimpleGrid>
        </Box>

        <Box my={8}>
          <StepperPengujian index={3} />
        </Box>

        <Box
          px={[4, 5, 6]}
          py={[3, 4, 4]}
          borderRadius={16}
          border={"1px solid var(--divider3)"}
          mb={8}
        >
          <HStack justify={"space-between"} mb={4}>
            <Text fontWeight={600}>Metode Uji</Text>

            <Button
              fontSize={[12, null, 14]}
              variant={"ghost"}
              colorScheme="ap"
              size={"sm"}
              as={Link}
              to={`/customer/pengujian/pendaftaran-pengujian/${noreg}/pilih-metode-uji`}
            >
              Ubah Metode
            </Button>
          </HStack>

          <Box mb={6}>
            <DetailMetodeUji />
          </Box>

          <HStack justify={"space-between"} mb={2}>
            <Text fontWeight={600}>Berkas Anda</Text>

            <Button
              fontSize={[12, null, 14]}
              variant={"ghost"}
              colorScheme="ap"
              size={"sm"}
              as={Link}
              to={`/customer/pengujian/pendaftaran-pengujian/${noreg}/unggah-berkas`}
            >
              Ubah Berkas
            </Button>
          </HStack>

          <Box mb={6}>
            <DetailFotoSampel />
          </Box>

          <Box mb={6}>
            <DetailFilePendukung />
          </Box>

          <Box mb={1}>
            <DetailSummary />
          </Box>
        </Box>

        <Stack
          flexDir={["column", "row", "row"]}
          align={["strech", null, "center"]}
          gap={4}
          mb={8}
          justify={"space-between"}
        >
          <Checkbox
            colorScheme="ap"
            alignItems={"flex-start"}
            maxW={"600px"}
            isChecked={agreement}
            onChange={() => {
              setAgreement((ps) => !ps);
            }}
          >
            <Text mt={checkBoxTextMt} fontSize={[12, null, 14]}>
              Saya dengan ini menyatakan setuju dengan{" "}
              <ChakraLink
                color="p.500"
                href="/policy"
                fontSize={["12px !important", null, "14px !important"]}
                isExternal
                fontWeight={600}
              >
                syarat dan ketentuan
              </ChakraLink>{" "}
              yang berlaku di LPPT POLTEKKES Kemenkes Semarang
            </Text>
          </Checkbox>

          <Button
            flexShrink={0}
            colorScheme="ap"
            className="lg-clicky btn-ap"
            h={"50px"}
            isDisabled={!agreement}
            isLoading={loading}
            onClick={saveTransaction}
          >
            Simpan & Kirim
          </Button>
        </Stack>
      </Container>
    </CustomerContainer>
  );
}
