import React from "react";
import { Box, HStack, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import TambahKategoriModal from "../../components/TambahKategoriModal";
import TabelKategoriProduk from "../../components/TabelKategoriProduk";
import SortKategoriProduk from "../../components/SortKategoriProduk";
import SearchKategoriProduct from "../../components/SearchKategoriProduct";

export default function AdminKategoriProduk() {
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
        <SearchKategoriProduct />
        <HStack justify={["flex-start", "flex-end"]}>
          <SortKategoriProduk />

          <TambahKategoriModal />
        </HStack>
      </SimpleGrid>

      <TabelKategoriProduk />
    </Box>
  );
}
