import React from "react";
import ThreeDotsMenu from "../ThreeDotsMenu";
import { Faqs } from "../../const/types";
import UpdateFaqsModal from "./UpdateFaqsModal";
import DeleteFaqsModal from "./DeleteFaqsModal";

type Props = {
  data: Faqs;
};

export default function AksiUpdateDeleteFaqs({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateFaqsModal data={data} />

      <DeleteFaqsModal id={data.id} />
    </ThreeDotsMenu>
  );
}
