import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";
import AksiDeleteMenuButton from "./AksiDeleteMenuButton";

type Props = {
  id: number;
};

export default function DeletePengujiModal({ id }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const onDelete = () => {
    setLoading(true);
    const url = `api/penguji/destroy`;
    const payload = {
      id_penguji: id,
    };
    clientRequest.then(() => {
      reqWithHeader
        .post(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Penguji Berhasil Dihapus",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
            setTrigger(!trigger);
          } else {
            toast({
              title: "Penguji Gagal Dihapus",
              description:
                response.data.message.name[0] || "Maaf terjadi kesalahan",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <AksiDeleteMenuButton onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Hapus Penguji</ModalHeader>

          <ModalBody>
            <Text>Anda yakin dengan menghapus Penguji ini?</Text>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              type="submit"
              form="tambahKategoriForm"
              colorScheme="red"
              variant={"outline"}
              className="lg-clicky"
              onClick={handleOnClose}
            >
              Tidak
            </Button>

            <Button
              type="submit"
              form="tambahKategoriForm"
              colorScheme="red"
              className="lg-clicky"
              onClick={onDelete}
              isLoading={loading}
            >
              Yakin
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
