import React, { useEffect, useState } from "react";
import CustomerContainer from "../../components/CustomerContainer";
import { Text, useColorModeValue, VStack } from "@chakra-ui/react";
import Container from "../../components/Container";
import clientRequest from "../../const/clientRequest";
import reqWithHeader from "../../const/reqWithHeader";
import { UserData } from "../../const/types";
import ComponentSpinner from "../../components/ComponentSpinner";
import ProfilForm from "../../components/ProfilForm";

export default function Profil() {
  const [data, setData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = "api/user/logindata";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((r) => {
          if (r.status === 200) {
            setData(r.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  return (
    <CustomerContainer active="">
      <VStack
        bg={useColorModeValue("var(--divider)", "#1c1c1c")}
        w={"100%"}
        flex={1}
      >
        <Container py={[4, 6, 8]} pt={[3, 5, 7]} flex={1}>
          <VStack
            bg={useColorModeValue("white", "dark")}
            p={6}
            borderRadius={24}
            align={"stretch"}
          >
            <Text fontWeight={600} fontSize={22}>
              Profil
            </Text>

            {!loading && data ? (
              <ProfilForm data={data} />
            ) : (
              <ComponentSpinner />
            )}
          </VStack>
        </Container>
      </VStack>
    </CustomerContainer>
  );
}
