import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ArrowDown, ArrowUp, CaretDown } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../../const/sizes";
import useSortFaqs from "../../globalState/useSortFaqs";

export default function SortFaqs() {
  const { sortBy, sortOrder, setSortBy, setSortOrder } = useSortFaqs();

  return (
    <Menu>
      <MenuButton
        as={Button}
        flexShrink={0}
        variant={"outline"}
        colorScheme="ap"
        pr={"12px"}
        rightIcon={<Icon as={CaretDown} fontSize={iconSize} />}
      >
        Urutkan
      </MenuButton>

      <MenuList
      // minW={"140px"}
      >
        <MenuGroup title="Pertanyaan">
          <MenuItem
            onClick={() => {
              setSortBy("pertanyaan");
              setSortOrder("asc");
            }}
          >
            <HStack
              className="sortItem"
              color={
                sortBy === "pertanyaan" && sortOrder === "asc" ? "p.500" : ""
              }
            >
              <Text>A-Z</Text>
              <Icon as={ArrowUp} fontSize={iconSize} />
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSortBy("pertanyaan");
              setSortOrder("desc");
            }}
          >
            <HStack
              className="sortItem"
              color={
                sortBy === "pertanyaan" && sortOrder === "desc" ? "p.500" : ""
              }
            >
              <Text>Z-A</Text>
              <Icon as={ArrowDown} fontSize={iconSize} />
            </HStack>
          </MenuItem>

          <MenuGroup title="Jawaban">
            <MenuItem
              onClick={() => {
                setSortBy("jawaban");
                setSortOrder("asc");
              }}
            >
              <HStack
                className="sortItem"
                color={
                  sortBy === "jawaban" && sortOrder === "asc" ? "p.500" : ""
                }
              >
                <Text>A-Z</Text>
                <Icon as={ArrowUp} fontSize={iconSize} />
              </HStack>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSortBy("jawaban");
                setSortOrder("desc");
              }}
            >
              <HStack
                className="sortItem"
                color={
                  sortBy === "jawaban" && sortOrder === "desc" ? "p.500" : ""
                }
              >
                <Text>Z-A</Text>
                <Icon as={ArrowDown} fontSize={iconSize} />
              </HStack>
            </MenuItem>
          </MenuGroup>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
