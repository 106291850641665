import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";
import RequiredForm from "./RequiredForm";
import useScreenWidth from "../lib/useGetScreenWidth";

export default function TambahKategoriModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const sw = useScreenWidth();

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      kode: "",
      namaKategori: "",
    },

    validationSchema: yup.object().shape({
      kode: yup.string().required("Kode Kategori harus diisi"),
      namaKategori: yup.string().required("Nama Kategori harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/product/kategori/store`;
      const payload = {
        kode: values.kode,
        name: values.namaKategori,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              resetForm();
              toast({
                title: "Kategori Produk Berhasil Ditambahkan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              setTrigger(!trigger);
            } else {
              toast({
                title: "Kategori Produk Gagal Ditambahkan",
                description:
                  response.data.message.name[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        flexShrink={0}
        leftIcon={<Icon as={Plus} fontSize={iconSize} />}
        pl={3}
        pr={sw < 874 ? 1 : 4}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
      >
        <Text display={sw < 874 ? "none" : "block"}>Tambah Kategori</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Tambah Kategori</ModalHeader>

          <ModalBody>
            <form id="tambahKategoriForm" onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.errors.kode ? true : false} mb={4}>
                <FormLabel>
                  Kode
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="kode"
                  placeholder="Masukkan kode kategori"
                  onChange={formik.handleChange}
                  value={formik.values.kode}
                />
                <FormErrorMessage>{formik.errors.kode}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.namaKategori ? true : false}
              >
                <FormLabel>
                  Nama Kategori
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="namaKategori"
                  placeholder="Masukkan nama kategori"
                  onChange={formik.handleChange}
                  value={formik.values.namaKategori}
                />
                <FormErrorMessage>
                  {formik.errors.namaKategori}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="tambahKategoriForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Tambahkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
