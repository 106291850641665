import React from "react";
import CustomerContainer from "../../components/CustomerContainer";
import Container from "../../components/Container";
import { Box, HStack, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import InformasiAkun from "../../components/InformasiPelanggan";
import StepperPengujian from "../../components/StepperPengujian";
import MetodeUjiList from "../../components/MetodeUjiList";
import useScreenWidth from "../../lib/useGetScreenWidth";
import InformasiTransaksi from "../../components/InformasiTransaksi";

export default function PilihMetodeUji() {
  const sw = useScreenWidth();

  return (
    <CustomerContainer active="Pengujian">
      <Container>
        <Box>
          {/* Bread Crumbs */}
          <HStack className="breadcrumb" mt={6}>
            <Link to="/customer" className="breadcrumbItem">
              Beranda
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Link to="/customer/pengujian" className="breadcrumbItem">
              Pengujian
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Text className="breadcrumbItem breadcrumbCurrent">
              Pilih Metode Uji
            </Text>
          </HStack>

          <Text fontSize={[22, null, 24]} fontWeight={600} mt={6} mb={6}>
            Pendaftaran Pengujian Online
          </Text>

          <SimpleGrid
            columns={sw < 900 ? 1 : 2}
            gap={6}
            px={[4, 5, 6]}
            pt={[5]}
            pb={[6]}
            bg={"var(--p500a3)"}
            borderRadius={16}
          >
            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Data Pelanggan
              </Text>

              <InformasiAkun />
            </Box>

            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Informasi Transaksi
              </Text>

              <InformasiTransaksi />
            </Box>
          </SimpleGrid>
        </Box>

        <Box my={8}>
          <StepperPengujian index={1} />
        </Box>

        <Box mb={8}>
          <MetodeUjiList />
        </Box>
      </Container>
    </CustomerContainer>
  );
}
