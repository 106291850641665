import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

type Props = {
  noreg: number;
};

export default function KonfirmasiDalamPengujian({ noreg }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDalamPengujian = () => {
    setLoading(true);
    const url = `api/transaction/${noreg}/confirmpengujian`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((r) => {
          if (r.status === 200) {
            toast({
              title: r.data.message,
              status: "success",
              duration: 10000,
              isClosable: true,
            });
            setTrigger(!trigger);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Konfirmasi Dalam Pengujian Gagal",
            description: "Maaf terjadi kesalahan",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <>
      <Button colorScheme="ap" className="btn-ap" w={"100%"} onClick={onOpen}>
        Dalam Pengujian
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Konfirmasi Dalam Pengujian</ModalHeader>
          <ModalBody>
            Status transaksi ini akan diubah ke <b>Dalam Pengujian</b>, apakah
            anda yakin?
          </ModalBody>
          <ModalFooter gap={2}>
            <Button className="btn clicky" onClick={onClose}>
              Tidak
            </Button>

            <Button
              colorScheme="ap"
              className="btn-ap clicky"
              onClick={onDalamPengujian}
              isLoading={loading}
            >
              Yakin
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
