import React from "react";
import { Icon, IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default function ThreeDotsMenu({ children }: Props) {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="button aksi"
        icon={<Icon as={DotsThreeVertical} fontSize={iconSize} weight="bold" />}
        borderRadius={"full"}
        variant={"ghost"}
        className="btn lg-clicky"
      />

      <MenuList minW={"140px"}>{children}</MenuList>
    </Menu>
  );
}
