import { HStack, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function LogoLoader() {
  return (
    <VStack id="fullPageLogo" flex={1} justify={"center"}>
      <Image
        src="/images/logoColor.png"
        mb={4}
        w={"100%"}
        maxW={"400px"}
        px={12}
      />

      <HStack>
        <Spinner size={"sm"} />

        <Text fontSize={[16, null, 18]} fontWeight={500}>
          Mohon tunggu
        </Text>
      </HStack>
    </VStack>
  );
}
