import React from "react";
import {
  HStack,
  SimpleGrid,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import SortCustomer from "../../components/SortCustomer";
import TabelCustomer from "../../components/TabelCustomer";
import SearchCustomer from "../../components/SearchCustomer";
import ExportCustomerByProvinsi from "../../components/ExportCustomerByProvinsi";

export default function AdminCustomer() {
  const bg = useColorModeValue("white", "dark");

  return (
    <VStack
      w={"100%"}
      align={"stretch"}
      gap={0}
      p={[4, null, 6]}
      bg={bg}
      borderRadius={16}
      // mb={5}
    >
      <SimpleGrid gap={4} columns={[1, 2]} mb={4}>
        <SearchCustomer />

        <HStack justify={["flex-start", "flex-end"]}>
          <SortCustomer />

          <ExportCustomerByProvinsi />
        </HStack>
      </SimpleGrid>

      <TabelCustomer />
    </VStack>
  );
}
