import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";
import useSearchBiayaTambahan from "../globalState/useSearchBiayaTambahan";

export default function SearchBiayaTambahan() {
  const { searchTerm, setSearchTerm } = useSearchBiayaTambahan();

  return (
    <InputGroup>
      <InputLeftElement>
        <Icon as={MagnifyingGlass} fontSize={iconSize} />
      </InputLeftElement>

      <Input
        placeholder={"Search"}
        maxW={"300px"}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        value={searchTerm}
      />
    </InputGroup>
  );
}
