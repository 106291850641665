import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { List } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import customerNav from "../const/customerNav";
import { Link, useNavigate } from "react-router-dom";
import useBackOnClose from "../lib/useBackOnClose";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import SedangKeluar from "./SedangKeluar";
import getUserData from "../lib/userData";
import { Cookies } from "typescript-cookie";

type Props = {
  active?: string;
};

export default function CustomerNavDrawer({ active }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = getUserData();

  useBackOnClose(isOpen, onClose);

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loadingKeluar, setLoadingKeluar] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSignout = () => {
    setLoadingKeluar(true);
    const url = `api/logout`;
    clientRequest.then(() => {
      reqWithHeader
        .post(url)
        .then((response) => {
          if (response.data.status === 200) {
            Cookies.remove("userData");
            navigate("/");
            toast({
              title: "Berhasil Keluar",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {loadingKeluar && <SedangKeluar />}

      <IconButton
        aria-label="menu"
        icon={<Icon as={List} fontSize={iconSize} />}
        variant={"outline"}
        colorScheme="ap"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />

        <DrawerContent>
          <DrawerBody
            as={VStack}
            gap={0}
            align={"stretch"}
            p={0}
            position={"relative"}
            bg={"p.600"}
            backdropFilter={"blur(10px)"}
            py={5}
          >
            <Image
              src={"/images/navDrawerBg.png"}
              w={"100%"}
              position={"absolute"}
              bottom={0}
              opacity={0.1}
            />

            <VStack gap={0} position={"relative"}>
              <HStack mt={2}>
                <ColorModeSwitcher
                  position={"absolute"}
                  left={0}
                  fontSize={20}
                />

                <DrawerCloseButton color={"white"} top={-2} right={4} />
              </HStack>

              <Image src="/images/logoWhite.png" w={"200px"} mb={2} mt={4} />
            </VStack>

            <VStack
              zIndex={2}
              gap={0}
              borderRadius={16}
              py={6}
              px={10}
              align={"stretch"}
              flex={1}
            >
              <HStack
                p={2}
                mb={6}
                bg={"var(--divider)"}
                borderRadius={8}
                as={Link}
                to={"/customer/profil"}
              >
                <Avatar
                  name={userData.name}
                  bg={"var(--divider3)"}
                  color={"white"}
                />

                <Box>
                  <Text fontWeight={600} lineHeight={1} mb={1}>
                    {userData.name}
                  </Text>
                  <Text noOfLines={1} lineHeight={1} fontSize={[12, null, 14]}>
                    {userData.email}
                  </Text>
                </Box>
              </HStack>

              <VStack w={"100%"} align={"stretch"} flex={1}>
                <Text
                  fontWeight={500}
                  opacity={0.5}
                  ml={1}
                  fontSize={[12, null, 14]}
                >
                  NAVIGASI
                </Text>
                {customerNav.map((n, i) => (
                  <Button
                    key={i}
                    as={Link}
                    to={n.link}
                    variant={"ghost"}
                    bg={active === n.name ? "blackAlpha.200" : ""}
                    className="btn"
                    borderRadius={8}
                    color={"white"}
                  >
                    <HStack w={"100%"}>
                      <Text fontWeight={500}>{n.name}</Text>
                    </HStack>
                  </Button>
                ))}
              </VStack>

              <Button
                colorScheme="white"
                variant={"outline"}
                onClick={handleSignout}
                isLoading={loadingKeluar}
              >
                Keluar
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
