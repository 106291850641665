import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Link as ChakraLink,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { Certificate, File, Flask, Newspaper } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import formatDate from "../lib/formatDate";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { Transaksi } from "../const/types";
import ComponentSpinner from "./ComponentSpinner";
import axios from "axios";
import formatNumber from "../lib/formatNumber";
import { useLocation } from "react-router-dom";
import useStatusBadgeColor from "../lib/useStatusBadgeColor";
import useDownload from "../lib/download";

type Props = {
  noreg: number;
  status: string;
};

export default function DetailTransaksiAdminModal({ noreg, status }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const location = useLocation();
  const pathname = location.pathname;
  const download = useDownload;

  const [data, setData] = useState<Transaksi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const toast = useToast();
  const colorSchemeStatus = useStatusBadgeColor;

  useEffect(() => {
    setLoading(true);

    const url = `api/transaction/${noreg}/getdetail`;
    if (isOpen) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => [setLoading(false)]);
      });
    }
  }, [isOpen, noreg]);

  const statusActionAdmin = [
    "Verifikasi Berkas",
    "Berkas Diverifikasi",
    "Verifikasi Berkas Lanjutan",
    "Berkas Lanjutan Diverifikasi",
    "Dalam Pengujian",
    "Pengujian Selesai",
  ];

  const buttonLabel = (status: string) => {
    switch (status) {
      default:
        return <Text>-</Text>;
      case "Verifikasi Berkas":
        return "Verifikasi Berkas";
      case "Berkas Diverifikasi":
        return "Input Kontrak & Tagihan";
      case "Verifikasi Berkas Lanjutan":
        return "Verifikasi Berkas Lanjutan";
      case "Berkas Lanjutan Diverifikasi":
        return "Input Tanggal Pengujian";
      case "Dalam Pengujian":
        return "Unggah Hasil Pengujian";
      case "Pengujian Selesai":
        return "Unggah Ulang Hasil Pengujian";
    }
  };

  const handleCta = () => {
    const cta = document.getElementById(`${status} - ${noreg}`);
    onClose();
    window.history.back();
    setTimeout(() => {
      cta?.click();
    }, 200);
  };

  // const handleUnduhSemuaBerkas = () => {
  //   const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/all`;
  //   clientRequest.then(() => {
  //     axios
  //       .get(url, {
  //         responseType: "blob",
  //         withCredentials: true,
  //         xsrfCookieName: "XSRF-TOKEN",
  //         xsrfHeaderName: "X-XSRF-TOKEN",
  //       })
  //       .then((r) => {
  //         if (r.status === 200) {
  //           console.log(r);
  //           const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
  //           const link = document.createElement("a");
  //           link.href = downloadUrl;
  //           link.setAttribute(
  //             "download",
  //             `Semua Berkas (${data?.nama_sertifikat}).${r.data.type
  //               .split("/")
  //               .pop()}`
  //           );
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           URL.revokeObjectURL(downloadUrl);
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         toast({
  //           title: "Gagal mengunduh",
  //           description: e?.response?.data?.message,
  //           status: "error",
  //           duration: 10000,
  //           isClosable: true,
  //         });
  //       });
  //   });
  // };

  // const handleUnduhFotoSampel = () => {
  //   const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkassampel`;
  //   clientRequest.then(() => {
  //     axios
  //       .get(url, {
  //         responseType: "blob",
  //         withCredentials: true,
  //         xsrfCookieName: "XSRF-TOKEN",
  //         xsrfHeaderName: "X-XSRF-TOKEN",
  //       })
  //       .then((r) => {
  //         if (r.status === 200) {
  //           const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
  //           const link = document.createElement("a");
  //           link.href = downloadUrl;
  //           link.setAttribute(
  //             "download",
  //             `Berkas Sampel (${data?.nama_sertifikat}).${r.data.type
  //               .split("/")
  //               .pop()}`
  //           );
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           URL.revokeObjectURL(downloadUrl);
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         toast({
  //           title: "Gagal mengunduh",
  //           description: e?.response?.data?.message,
  //           status: "error",
  //           duration: 10000,
  //           isClosable: true,
  //         });
  //       });
  //   });
  // };

  // const handleUnduhFilePendukung = () => {
  //   const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkaspendukung`;
  //   clientRequest.then(() => {
  //     axios
  //       .get(url, {
  //         responseType: "blob",
  //         withCredentials: true,
  //         xsrfCookieName: "XSRF-TOKEN",
  //         xsrfHeaderName: "X-XSRF-TOKEN",
  //       })
  //       .then((r) => {
  //         if (r.status === 200) {
  //           const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
  //           const link = document.createElement("a");
  //           link.href = downloadUrl;
  //           link.setAttribute(
  //             "download",
  //             `Berkas Pendukung (${data?.nama_sertifikat}).${r.data.type
  //               .split("/")
  //               .pop()}`
  //           );
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           URL.revokeObjectURL(downloadUrl);
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         toast({
  //           title: "Gagal mengunduh",
  //           description: e?.response?.data?.message,
  //           status: "error",
  //           duration: 10000,
  //           isClosable: true,
  //         });
  //       });
  //   });
  // };

  const handleUnduhBerkasKontrak = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkaskontrak`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Kontrak (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleUnduhBuktiBayar = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkasbukti`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Bukti Bayar (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleExportKuisioner = () => {
    setLoadingExport(true);

    // TODO kurang api export kuisioner per trans
    const url = `${process.env.REACT_APP_BASE_URL}api/transaction/${noreg}/download`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `Data Transaksi ${noreg}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal export",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoadingExport(false);
        });
    });
  };

  return (
    <>
      <Button
        colorScheme="ap"
        variant={"outline"}
        className="lg-clicky"
        onClick={onOpen}
      >
        Detail
      </Button>

      <Modal
        isOpen={isOpen}
        size={"lg"}
        onClose={handleOnClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent maxW={"650px"}>
          <ModalCloseButton />

          <ModalHeader>Detail Transaksi</ModalHeader>

          {!loading && data ? (
            <ModalBody>
              {data.status === "Berkas Ditolak" && (
                <Alert
                  className="alert"
                  variant={"left-accent"}
                  colorScheme="red"
                  mb={6}
                >
                  <Box>
                    <AlertTitle fontSize={[18, null, 20]} color={"red"}>
                      Berkas Ditolak
                    </AlertTitle>
                    <AlertDescription>{data.alasan_tolak}</AlertDescription>
                  </Box>
                </Alert>
              )}

              {data.status === "Berkas Lanjutan Ditolak" && (
                <Alert
                  className="alert"
                  variant={"left-accent"}
                  colorScheme="red"
                  mb={6}
                >
                  <Box>
                    <AlertTitle fontSize={[18, null, 20]} color={"red"}>
                      Berkas Lanjutan Ditolak
                    </AlertTitle>
                    <AlertDescription>
                      {data.alasan_tolak_kontrak}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <Stack
                flexDir={["column", null, "row"]}
                gap={4}
                className="detailTransaksiItem"
              >
                <Text className="detailTransaksiItemLabel">Status</Text>

                <Badge
                  colorScheme={colorSchemeStatus(data.status)}
                  w={"fit-content"}
                >
                  {data.status}
                </Badge>
              </Stack>

              <Stack
                flexDir={["column", null, "row"]}
                gap={4}
                className="detailTransaksiItem"
              >
                <Text className="detailTransaksiItemLabel">No. Registrasi</Text>

                <Box>
                  <Text fontWeight={500}>
                    {noreg.toString().padStart(3, "0")}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Tanggal Transaksi
                </Text>

                <Box>
                  <Text fontWeight={500}>{formatDate(data.tgl_transaksi)}</Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Nama Sertifikat
                </Text>

                <Box>
                  <Text fontWeight={500}>{data.nama_sertifikat}</Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Metode Uji</Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.product?.name ? 1 : 0.5}
                  >
                    {data?.product?.name || "Metode Uji belum dipilih"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Total Tagihan</Text>
                <Box>
                  <Text fontWeight={500} opacity={data?.tagihan ? 1 : 0.5}>
                    {data.tagihan
                      ? `Rp ${formatNumber(data.tagihan)}`
                      : "Saat ini belum memiliki Total Tagihan"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Tanggal Pengujian
                </Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.tgl_pengujian ? 1 : 0.5}
                  >
                    {data?.tgl_pengujian
                      ? formatDate(data?.tgl_pengujian)
                      : "Saat ini belum memiliki Tanggal Pengujian"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Penguji</Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.penguji?.nama ? 1 : 0.5}
                  >
                    {data?.penguji
                      ? `${data?.penguji?.nama} (${data?.penguji?.id})`
                      : "Saat ini belum memiliki Penguji"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                flexDir={["column", null, "row"]}
                gap={4}
                className="detailTransaksiItem"
              >
                <Text className="detailTransaksiItemLabel">Kuisioner</Text>

                <HStack justify={"space-between"} w={"100%"}>
                  <Text fontWeight={500} opacity={data?.nilai ? 1 : 0.5}>
                    {data?.nilai
                      ? `Total skor : ${data?.nilai}`
                      : "Saat ini belum mengisi kuisioner"}
                  </Text>

                  {data?.nilai && (
                    <Button
                      colorScheme="ap"
                      className="btn-ap"
                      onClick={handleExportKuisioner}
                      isLoading={loadingExport}
                    >
                      Export
                    </Button>
                  )}
                </HStack>
              </Stack>

              {/* Foto Sampel */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Foto Sampel</Text>

                {data.berkasutama.length > 0 ? (
                  <Box w={"100%"}>
                    {/* <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhFotoSampel}
                    >
                      Unduh
                    </Button> */}

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      {data.berkasutama.map((d, i) => (
                        <VStack
                          key={i}
                          gap={0}
                          borderRadius={8}
                          bg={"var(--divider)"}
                          p={2}
                          border={"1px solid var(--divider3)"}
                          mb={2}
                          align={"center"}
                          as={ChakraLink}
                          href={`${process.env.REACT_APP_VPS_DOMAIN}${d.path}`}
                          isExternal
                        >
                          <Image
                            src={`${process.env.REACT_APP_VPS_DOMAIN}${d.path}`}
                            borderRadius={8}
                            w={"100%"}
                            h={"180px"}
                            objectFit={"cover"}
                            mb={2}
                          />
                          <Box px={2}>
                            <Text
                              textAlign={"center"}
                              noOfLines={1}
                              fontSize={[12, null, 14]}
                            >
                              {d.name}
                            </Text>
                            <Text
                              textAlign={"center"}
                              opacity={0.5}
                              fontSize={[12, null, 14]}
                            >
                              {d.keterangan}
                            </Text>
                          </Box>
                        </VStack>
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>Saat ini belum memiliki Foto Sampel</Text>
                )}
              </Stack>

              {/* FIle Pendukung */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">File Pendukung</Text>

                {data.berkaspendukung.length > 0 ? (
                  <Box w={"100%"}>
                    {/* <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhFilePendukung}
                    >
                      Unduh
                    </Button> */}

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      {data.berkaspendukung.map((d, i) => (
                        <Box key={i}>
                          <VStack
                            gap={0}
                            borderRadius={8}
                            bg={"var(--divider)"}
                            p={2}
                            border={"1px solid var(--divider3)"}
                            mb={2}
                            align={"center"}
                            as={ChakraLink}
                            href={`${process.env.REACT_APP_VPS_DOMAIN}${d.path}`}
                            isExternal
                          >
                            <Icon
                              as={File}
                              fontSize={100}
                              weight="fill"
                              mb={4}
                              mt={2}
                            />
                            <Box px={2} w={"100%"}>
                              <Text
                                textAlign={"center"}
                                fontSize={[12, null, 14]}
                                noOfLines={1}
                              >
                                {d.name}
                              </Text>
                              <Text
                                textAlign={"center"}
                                fontSize={[12, null, 14]}
                                opacity={0.5}
                                noOfLines={1}
                              >
                                {d.size}
                              </Text>
                              <Text
                                textAlign={"center"}
                                opacity={0.5}
                                fontSize={[12, null, 14]}
                              >
                                {d.keterangan}
                              </Text>
                            </Box>
                          </VStack>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat ini belum memiliki File Pendukung
                  </Text>
                )}
              </Stack>

              {/* File Kontrak */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">File Kontrak</Text>

                {data.berkaskontrakttd.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhBerkasKontrak}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        align={"center"}
                        as={ChakraLink}
                        href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkaskontrakttd[0].path}`}
                        isExternal
                      >
                        <Icon as={File} fontSize={100} weight="light" mb={2} />
                        <Box px={2}>
                          <Text textAlign={"center"} fontSize={[12, null, 14]}>
                            Berkas Kontrak TTD
                          </Text>
                          <Text
                            textAlign={"center"}
                            fontSize={[12, null, 14]}
                            opacity={0.5}
                          >
                            {data.berkaskontrakttd[0].size}
                          </Text>
                        </Box>
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat Ini Belum memiliki Berkas Kontrak
                  </Text>
                )}
              </Stack>

              {/* Bukti Pembayaran */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Bukti Pembayaran
                </Text>

                {data.berkasbuktipembayaran.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhBuktiBayar}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        as={ChakraLink}
                        href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasbuktipembayaran[0].path}`}
                        isExternal
                      >
                        <Image
                          src={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasbuktipembayaran[0].path}`}
                        />
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat ini belum memiliki Bukti Pembayaran
                  </Text>
                )}
              </Stack>

              {/* File Berita Acara */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Berita Acara</Text>

                {data.berkasberitaacara?.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={() => {
                        download(
                          `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkasberitaacara`,
                          "Berkas Berita Acara",
                          data
                        );
                      }}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        align={"center"}
                        as={ChakraLink}
                        href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasberitaacara[0].path}`}
                        isExternal
                      >
                        <Icon
                          as={Newspaper}
                          fontSize={100}
                          weight="light"
                          my={2}
                        />
                        <Box px={2}>
                          <Text textAlign={"center"}>Berita Acara</Text>
                        </Box>
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat Ini Belum memiliki Berkas Berita Acara
                  </Text>
                )}
              </Stack>

              {/* File LHU */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">LHU</Text>

                {data.berkaslhu?.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={() => {
                        download(
                          `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkaslhu`,
                          "Berkas LHU",
                          data
                        );
                      }}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        align={"center"}
                        as={ChakraLink}
                        href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkaslhu[0].path}`}
                        isExternal
                      >
                        <Icon as={Flask} fontSize={100} weight="light" my={2} />
                        <Box px={2}>
                          <Text textAlign={"center"}>LHU</Text>
                        </Box>
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>Saat Ini Belum memiliki Berkas LHU</Text>
                )}
              </Stack>

              {/* File Sertifikat */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Sertifikat</Text>

                {data.berkasserti?.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={() => {
                        download(
                          `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkassertifikat`,
                          "Berkas Sertifkat",
                          data
                        );
                      }}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        align={"center"}
                        as={ChakraLink}
                        href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasserti[0].path}`}
                        isExternal
                      >
                        <Icon
                          as={Certificate}
                          fontSize={100}
                          weight="light"
                          my={2}
                        />
                        <Box px={2}>
                          <Text textAlign={"center"}>Sertifikat</Text>
                        </Box>
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat Ini Belum memiliki Berkas Sertifikat
                  </Text>
                )}
              </Stack>
            </ModalBody>
          ) : (
            <ComponentSpinner h={"525px"} />
          )}

          <ModalFooter gap={2}>
            {/* <Button
              variant={"outline"}
              colorScheme="ap"
              onClick={handleUnduhSemuaBerkas}
              className="lg-clicky"
            >
              Unduh Semua Berkas
            </Button> */}

            {statusActionAdmin.includes(status) && pathname !== "/admin" && (
              <Button
                colorScheme="ap"
                className="lg-clicky btn-ap"
                onClick={handleCta}
              >
                {buttonLabel(status)}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
