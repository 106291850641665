import { Text } from "@chakra-ui/react";
import React, { useState } from "react";

export default function NoOflinesText(props: any) {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <Text
      noOfLines={expand ? 0 : 1}
      onClick={() => {
        setExpand((expand) => !expand);
      }}
      cursor={"pointer"}
      w={props?.w || ""}
    >
      {props?.children}
    </Text>
  );
}
