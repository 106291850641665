import { Text } from "@chakra-ui/react";
import React from "react";
import VerifikasiBerkasModal from "../components/VerifikasiBerkasModal";
import InputKontrakTagihanModal from "./InputKontrakTagihanModal";
import VerifikasiBerkasLanjutanModal from "./VerifikasiBerkasLanjutanModal";
import UnggahBeritaAcara from "./UnggahBeritaAcara";
import InputTanggalPengujianModal from "./InputTanggalPengujianModal";
import ChangeStatusDalamPengujian from "./KonfirmasiDalamPengujian";
import UnggahLhu from "./UnggahLhu";
import UnggahSertif from "./UnggahSertif";

export default function AdminAksiPengujian(props: any) {
  const noreg = props.noreg;
  const status = props.status;

  let statusKey = status;
  if (status.includes("Tanggal Pengujian")) {
    statusKey = "Tanggal Pengujian";
  }

  switch (statusKey) {
    default:
      return <Text>-</Text>;
    case "Verifikasi Berkas":
      return <VerifikasiBerkasModal noreg={noreg} />;
    case "Berkas Diverifikasi":
      return <InputKontrakTagihanModal noreg={noreg} />;
    case "Verifikasi Berkas Lanjutan":
      return <VerifikasiBerkasLanjutanModal noreg={noreg} />;
    case "Berkas Lanjutan Diverifikasi":
      return <InputTanggalPengujianModal noreg={noreg} />;
    case "Tanggal Pengujian":
      return <ChangeStatusDalamPengujian noreg={noreg} />;
    case "Dalam Pengujian":
      return <UnggahBeritaAcara noreg={noreg} />;
    case "Berita Acara Terbit":
      return <UnggahLhu noreg={noreg} />;
    case "LHU Terbit":
      return <UnggahSertif noreg={noreg} />;
    // case "Pengujian Selesai":
    // return <UnggahUlangHasilPengujian noreg={noreg} />;
  }
}
