import {
  Center,
  Checkbox,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ComponentSpinner from "./ComponentSpinner";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useParams } from "react-router-dom";
import useUnggahBerkasTriggerGetFotoSampel from "../globalState/useTrigger";
import { FotoSampel } from "../const/types";
import useSelectedFotoSampel from "../globalState/useSelectedFotoSampel";
import useValidateBerkas from "../globalState/useValidateBerkas";

export default function UnggahBerkasFotoSampelList() {
  const [data, setData] = useState<FotoSampel[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();
  const { trigger } = useUnggahBerkasTriggerGetFotoSampel();
  const { setFotoSampel } = useValidateBerkas();

  const { selectedFotoSampel, setSelectedFotoSampel } = useSelectedFotoSampel();

  const handleCheckboxChange = (imgId: number) => {
    let res: number[] = [];
    if (!selectedFotoSampel.includes(imgId)) {
      res = [...(selectedFotoSampel as number[]), imgId];
    } else {
      res = selectedFotoSampel.filter((id) => id !== imgId);
    }
    setSelectedFotoSampel(res);
  };

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/berkasutama`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.data.status === 200) {
              setData(response.data.data);
              if (response.data.data.length > 0) {
                setFotoSampel(true);
              } else {
                setFotoSampel(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg, trigger, setFotoSampel]);

  if (!loading && data && data?.length > 0) {
    return (
      <SimpleGrid columns={[2, 3, null, 4]} gap={4}>
        {data.map((file, i) => (
          <Checkbox
            key={i}
            colorScheme="ap"
            className="checkboxFile"
            onChange={(e) => handleCheckboxChange(file.id)}
            justifyContent={"center"}
          >
            <VStack align={"stretch"} w={"100%"} aspectRatio={"1/1"}>
              <Image
                src={`${process.env.REACT_APP_VPS_DOMAIN}${file?.path}`}
                borderRadius={8}
                boxSize={"100%"}
                objectFit={"cover"}
              />
              <Text noOfLines={1} textAlign={"center"}>
                {file.name}
              </Text>
            </VStack>
          </Checkbox>
        ))}
      </SimpleGrid>
    );
  }

  if ((!loading && !data) || (data && data.length <= 0)) {
    return (
      <Center p={5} flexDir={"column"}>
        <Image src="/vector/sampleImage.webp" h={"250px"} />
        <Text textAlign={"center"} fontWeight={500} maxW={"400px"}>
          Maaf, Saat Ini Anda Belum Memiliki Foto Sampel yang Tersedia
        </Text>
      </Center>
    );
  }

  return <ComponentSpinner h={"300px"} />;
}
