import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import * as yup from "yup";
import { useFormik } from "formik";
import RequiredForm from "./RequiredForm";
import useBackOnClose from "../lib/useBackOnClose";
import FileInput from "./FileInput";
import { useParams } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

export default function TambahFotoSampelModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      nama: "",
      fotoSampel: [] as File[] | null,
      keterangan: "",
    },

    validationSchema: yup.object().shape({
      nama: yup.string().required("Nama Foto Sampel Harus diisi"),
      fotoSampel: yup
        .array()
        .min(1, "Foto Sampel harus diunggah")
        .required("Foto Sampel harus diunggah")
        .test(
          "fileType",
          "Hanya file JPG, PNG, HEIC yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/heic" ||
                file.type === "image/HEIC"
            )
        )
        .test("fileSize", "Ukuran maksimal file adalah 10 MB", (value) =>
          value.every((file) => file.size <= 10000000)
        ),
      keterangan: yup.string(),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaksi/${noreg}/berkasutama`;
      const payload = new FormData();
      payload.append("name", values.nama);
      if (values.fotoSampel) {
        values.fotoSampel.forEach((file) => {
          payload.append("file", file);
        });
      }
      payload.append("keterangan", values.keterangan);

      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
              toast({
                title: "Foto Sampel Berhasil Ditambahkan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              resetForm();
              setTrigger(!trigger);
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Foto Sampel Gagal Ditambahkan",
              description: "Maaf terjadi kesalahan",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        leftIcon={<Icon as={Plus} fontSize={iconSize} weight="bold" />}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
        pl={"12px"}
      >
        Tambah Foto Sampel
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Masukkan Foto Sampel</ModalHeader>

          <ModalBody>
            <Alert
              status="warning"
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <AlertIcon mt={"2px"} />
              <Box>
                <AlertTitle>
                  <Text
                    fontSize={[18, null, 20]}
                    fontWeight={600}
                    color={useColorModeValue("orange.500", "orange.200")}
                  >
                    PERHATIAN!!
                  </Text>
                </AlertTitle>
                <AlertDescription>
                  Format Gambar <b>PNG</b>, <b>JPG</b>, <b>HEIC</b>{" "}
                  <b>maks. 10 MB</b>
                </AlertDescription>
              </Box>
            </Alert>

            <form id="fotoSampelForm" onSubmit={formik.handleSubmit}>
              <FormControl mb={4} isInvalid={formik.errors.nama ? true : false}>
                <FormLabel>
                  Nama Foto Sampel
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="nama"
                  onChange={formik.handleChange}
                  placeholder="Masukkan nama foto sampel"
                  value={formik.values.nama}
                />
                <FormErrorMessage>{formik.errors.nama}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.fotoSampel ? true : false}
                mb={4}
              >
                <FormLabel>
                  Unggah Foto Sampel
                  <RequiredForm />
                </FormLabel>
                <FileInput
                  formik={formik}
                  name={"fotoSampel"}
                  accept={".jpg, .jpeg, .png"}
                />
                <FormErrorMessage>{formik.errors.fotoSampel}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.keterangan ? true : false}>
                <FormLabel>Keterangan</FormLabel>
                <Textarea
                  name="keterangan"
                  onChange={formik.handleChange}
                  placeholder="Masukkan catatan anda"
                  value={formik.values.keterangan}
                />
                <FormErrorMessage>{formik.errors.keterangan}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="fotoSampelForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Simpan & Lanjutkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
