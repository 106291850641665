import React from "react";
import ThreeDotsMenu from "../ThreeDotsMenu";
import { KontakWa } from "../../const/types";
import UpdateKontakWaModal from "./UpdateKontakWaModal";
import DeleteKontakWaModal from "./DeleteKontakWaModal";

type Props = {
  data: KontakWa;
};

export default function AksiUpdateDeleteKontakWa({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateKontakWaModal data={data} />

      <DeleteKontakWaModal id={data.id} />
    </ThreeDotsMenu>
  );
}
