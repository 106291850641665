import { useNavigate } from "react-router-dom";
import { Transaksi } from "../const/types";
import { Button } from "@chakra-ui/react";

interface Props {
  data: Transaksi;
}

export default function LihatLhuButton({ data }: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!data?.is_quesioner) {
      navigate(`/customer/kuisioner/${data.id}`);
    } else {
      window.open(
        `${process.env.REACT_APP_VPS_DOMAIN}${data.berkaslhu?.[0]?.path}`,
        "_blank"
      );
    }
  };

  return (
    <Button
      colorScheme="ap"
      className="btn-ap"
      w={"100%"}
      onClick={handleClick}
    >
      Lihat LHU
    </Button>
  );
}
