import { HStack, Image } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function LandingHeader() {
  const location = useLocation();
  const path = location.pathname;
  const logoWhiteRoutes = ["/", "/policy", "/faqs"];

  return (
    <HStack as={Link} to={"/"}>
      <Image
        // src={useColorModeValue(
        //   "/images/logoLight.png",
        //   "/images/logoDark.png"
        // )}
        src={
          logoWhiteRoutes.includes(path)
            ? "/images/logoWhite.png"
            : "/images/logoColor.png"
        }
        h={"48px"}
      />
    </HStack>
  );
}
