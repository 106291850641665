import { Box, HStack, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import TambahFaqModal from "../../components/AdminFaqs/TambahFaqModal";
import SearchFaqs from "../../components/AdminFaqs/SearchFaqs";
import SortFaqs from "../../components/AdminFaqs/SortFaqs";
import TabelFaqs from "../../components/AdminFaqs/TabelFaqs";

export default function AdminFaqs() {
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
        <SearchFaqs />
        <HStack justify={["flex-start", "flex-end"]}>
          <SortFaqs />

          <TambahFaqModal />
        </HStack>
      </SimpleGrid>

      <TabelFaqs />
    </Box>
  );
}
