import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Container from "./Container";
import useScreenHeight from "../lib/useScreenHeight";

export default function HeroImages() {
  const sh = useScreenHeight();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [key, setKey] = useState(0); // State untuk mengatur kunci

  const images = [
    "/images/hero/0.png",
    "/images/hero/1.png",
    "/images/hero/2.png",
  ];
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setKey((prevKey) => prevKey + 1); // Update key setiap kali gambar berubah
    }, 5000);

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <VStack
      w={"100%"}
      gap={0}
      position={"relative"}
      overflow={"clip"}
      bg={"p.500"}
      bgImage={
        currentImageIndex === 0
          ? images[images.length - 1]
          : images[(currentImageIndex - 1) % images.length]
      }
      bgPos={"center"}
      bgSize={"cover"}
    >
      <Image
        key={key} // Atur kunci untuk memicu render ulang elemen saat gambar berubah
        src={images[currentImageIndex]}
        objectFit={"cover"}
        w={"100%"}
        h={"100%"}
        boxShadow={"10px 10px 0 0 var(--divider)"}
        position={"absolute"}
        left={0}
        top={0}
        zIndex={1}
        animation={"fade-in 1s"}
      />

      <VStack
        zIndex={2}
        w={"100%"}
        minH={sh < 1000 ? "calc(100vh - 80px)" : "1000px"}
        justify={"center"}
        bg={
          "linear-gradient(to right, var(--p500a1), var(--p500a2), var(--p500a3))"
        }
        color={"white"}
        align={"stretch"}
      >
        <Container>
          <Box mb={8}>
            <Heading
              mb={8}
              fontSize={[32, 42, 52]}
              lineHeight={1.1}
              maxW={"950px"}
            >
              Selamat Datang di Website Pendaftaran Pengujian Laboratorium
            </Heading>

            <Text maxW={"650px"}>
              Pastikan Hasil Radiologi <b>Akurat</b> dan <b>Aman</b> dengan
              Pengujian Poltekkes Kemenkes Semarang!
            </Text>
          </Box>
        </Container>
      </VStack>
    </VStack>
  );
}
