import { Box, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { File } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { FilePendukung } from "../const/types";
import { useParams } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";

export default function DetailFilePendukung() {
  const [data, setData] = useState<FilePendukung[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/berkaspendukung`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg]);

  if (!loading && data) {
    return (
      <Box>
        <Text fontWeight={600} mb={4}>
          File Pendukung
        </Text>

        <SimpleGrid columns={[2, 3, 4]} gap={4}>
          {data.map((d, i) => (
            <VStack
              key={i}
              gap={0}
              borderRadius={8}
              bg={"var(--divider)"}
              p={2}
              py={5}
              border={"1px solid var(--divider3)"}
            >
              <Icon as={File} fontSize={[50, null, 70]} weight="light" mb={2} />
              <Text noOfLines={1} fontWeight={500} textAlign={"center"}>
                {d.name}
              </Text>
              <Text
                textAlign={"center"}
                fontSize={[12, null, 14]}
                opacity={0.5}
              >
                {d.size}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
