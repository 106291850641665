import { Button, Text, Link as ChakraLink } from "@chakra-ui/react";
import React from "react";
import UnggahBerkasLanjutanModal from "./UnggahBerkasLanjutanModal";
import { Link } from "react-router-dom";
import { Transaksi } from "../const/types";
import LihatLhuButton from "./LihatLhuButton";

interface Props {
  data: Transaksi;
}

export default function CustomerAksiPengujian({ data }: Props) {
  // console.log(data);

  switch (data.status) {
    default:
      return <Text>-</Text>;
    case "Metode Uji Belum Dipilih":
      return (
        <Button
          w={"100%"}
          colorScheme="ap"
          className="lg-clicky btn-ap"
          as={Link}
          to={`/customer/pengujian/pendaftaran-pengujian/${data.id}/pilih-metode-uji`}
          fontSize={["13px !important", null, "15px !important"]}
        >
          Pilih Metode Uji
        </Button>
      );
    case "Berkas Belum Lengkap":
      return (
        <Button
          w={"100%"}
          colorScheme="ap"
          className="lg-clicky btn-ap"
          as={Link}
          to={`/customer/pengujian/pendaftaran-pengujian/${data.id}/unggah-berkas`}
          fontSize={["13px !important", null, "15px !important"]}
        >
          Unggah Berkas
        </Button>
      );
    case "Verifikasi Berkas Ditolak":
      return (
        <Button
          w={"100%"}
          colorScheme="ap"
          className="lg-clicky btn-ap"
          as={Link}
          to={`/customer/pengujian/pendaftaran-pengujian/${data.id}/unggah-berkas`}
          fontSize={["13px !important", null, "15px !important"]}
        >
          Unggah Berkas
        </Button>
      );
    case "Berkas Lanjutan Belum Diunggah":
      return <UnggahBerkasLanjutanModal noreg={data.id} />;
    case "Verifikasi Berkas Lanjutan Ditolak":
      return <UnggahBerkasLanjutanModal noreg={data.id} />;
    case "Berita Acara Terbit":
      return (
        <Button
          as={ChakraLink}
          href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasberitaacara?.[0]?.path}`}
          isExternal
          colorScheme="ap"
          className="btn-ap"
          w={"100%"}
        >
          Lihat Berita Acara
        </Button>
      );
    case "LHU Terbit":
      return (
        // <Button
        //   as={ChakraLink}
        //   href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkaslhu?.[0]?.path}`}
        //   isExternal
        //   colorScheme="ap"
        //   className="btn-ap"
        //   w={"100%"}
        // >
        //   Lihat LHU
        // </Button>
        <LihatLhuButton data={data} />
      );
    case "Sertifikat Terbit":
      // return <UnduhHasilPengujian noreg={noreg} data={data} />;
      return (
        <Button
          as={ChakraLink}
          href={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasserti?.[0]?.path}`}
          isExternal
          colorScheme="ap"
          className="btn-ap"
          w={"100%"}
        >
          Lihat Sertifikat
        </Button>
      );
  }
}
