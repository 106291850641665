import {
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CaretDown, MagnifyingGlass } from "@phosphor-icons/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { iconSize } from "../const/sizes";
import ComponentSpinner from "./ComponentSpinner";
import { Provinsi } from "../const/types";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import clientRequest from "../const/clientRequest";

export default function ExportCustomerByProvinsi() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const toast = useToast();

  const [data, setData] = useState<Provinsi[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const getData = useCallback(() => {
    setLoading(true);
    const url =
      "https://rezahawari.github.io/api-wilayah-indonesia/api/provinces.json";
    axios
      .get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen, getData]);

  const options = data?.map((d) => ({
    value: d.id,
    label: d.name,
    original: d,
  }));

  const initialRef = useRef(null);
  const [search, setSearch] = useState<string>("");
  const filteredOptions = options?.filter((option) =>
    option.label.toLowerCase().includes(search.toLocaleLowerCase())
  );

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,

    initialValues: {
      provinsi: null as Provinsi | null,
    },

    validationSchema: yup.object().shape({
      provinsi: yup.mixed().required("Provinsi harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      // console.log(values);
    },
  });

  const exportByProvinsi = () => {
    setLoadingExport(true);
    const url = `${process.env.REACT_APP_BASE_URL}api/customer/download`;
    const payload = {
      provinsi: formik.values.provinsi,
    };
    clientRequest.then(() => {
      axios
        .post(url, payload, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Data Pelanggan ${formik.values.provinsi?.name}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal export",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoadingExport(false);
        });
    });
  };

  return (
    <>
      <Button
        colorScheme="ap"
        className="btn-ap"
        rightIcon={<Icon as={CaretDown} />}
        onClick={onOpen}
      >
        Export
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        scrollBehavior="inside"
        initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <ModalContent overflow={"clip"}>
          <ModalCloseButton />
          <ModalHeader pr={6}>
            <Text>Pilih Provinsi untuk Export</Text>
            <InputGroup mt={4}>
              <InputLeftElement>
                <Icon as={MagnifyingGlass} fontSize={iconSize} />
              </InputLeftElement>

              <Input
                ref={initialRef}
                w={"100%"}
                placeholder={"Pencarian"}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
            </InputGroup>
          </ModalHeader>

          <ModalBody className="scrollY">
            {!loading && options && (
              <VStack align={"stretch"} py={1}>
                {filteredOptions && filteredOptions?.length > 0 ? (
                  filteredOptions?.map((option, i) => (
                    <Button
                      className="btn"
                      bg={
                        formik.values.provinsi &&
                        formik.values.provinsi.id === option.value
                          ? "var(--p500a3) !important"
                          : ""
                      }
                      key={i}
                      onClick={() => {
                        formik.setFieldValue("provinsi", option.original);
                      }}
                    >
                      <HStack justify={"flex-start"} w={"100%"}>
                        <Text>{option.label}</Text>
                      </HStack>
                    </Button>
                  ))
                ) : (
                  <Text textAlign={"center"}>Opsi tidak ditemukan</Text>
                )}
              </VStack>
            )}

            {loading && <ComponentSpinner />}

            {!loading && !options && (
              <HStack>
                <Button
                  mx={"auto"}
                  colorScheme="ap"
                  variant={"outline"}
                  onClick={() => {
                    getData();
                  }}
                >
                  Muat Ulang
                </Button>
              </HStack>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="ap"
              className="btn-ap clicky"
              isDisabled={formik.values.provinsi ? false : true}
              onClick={exportByProvinsi}
              isLoading={loadingExport}
            >
              Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
