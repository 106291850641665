import React from "react";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import TabelBiayaTambahan from "../../components/TabelBiayaTambahan";
import TambahBiayaTambahanModal from "../../components/TambahBiayaTambahanModal";
import SearchBiayaTambahan from "../../components/SearchBiayaTambahan";
import SortBiayaTambahan from "../../components/SortBiayaTambahan";

export default function AdminBiayaTambahan() {
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <HStack mb={4}>
        <SearchBiayaTambahan />

        <SortBiayaTambahan />

        <TambahBiayaTambahanModal />
      </HStack>

      <TabelBiayaTambahan />
    </Box>
  );
}
