import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Kecamatan, Kota, Provinsi, UserData } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import {
  useToast,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import RequiredForm from "./RequiredForm";
import SelectProvinsi from "./SelectProvinsi";
import SelectKota from "./SelectKota";
import SelectKecamatan from "./SelectKecamatan";

type Props = {
  data: UserData;
};

export default function ProfilForm({ data }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  // console.log(data);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,

    initialValues: {
      // username: "",
      // kataSandi: "",
      // ulangKataSandi: "",
      namaLengkap: data.name,
      institusi: data.institusi || "",
      alamat: data.alamat_institusi || "",
      provinsi: data.provinsi || (null as Provinsi | null),
      kota: data.kota || (null as Kota | null),
      kecamatan: data.kecamatan || (null as Kecamatan | null),
      kodepos: data.kode_pos || "",
      telepon: data.hp || "",
      telp_institusi: data.telp_institusi || "",
    },

    validationSchema: yup.object().shape({
      // username: yup.string().required("Username harus diisi"),
      // kataSandi: yup.string().required("Kata Sandi harus diisi"),
      // ulangKataSandi: yup
      //   .string()
      //   .required("Ulang Kata Sandi harus diisi")
      //   .oneOf([yup.ref("kataSandi")], "Kata sandi tidak cocok"),
      namaLengkap: yup.string().required("Nama Lengkap harus diisi"),
      institusi: yup.string().required("Institusi harus diisi"),
      alamat: yup.string().required("Alamat institusi harus diisi"),
      provinsi: yup.mixed().required("Provinsi harus diisi"),
      kota: yup.mixed().required("Kota harus diisi"),
      kecamatan: yup.mixed().required("Kecamatan harus diisi"),
      kodepos: yup.string().required("Kodepos harus diisi"),
      telepon: yup.string().required("Telepon harus diisi"),
      telp_institusi: yup
        .string()
        .required("Telepon institusi atau Fax harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values);
      setLoading(true);
      const url = `/api/user/profil/store`;
      const payload = {
        // username: values.username,
        // password: values.kataSandi,
        // password_confirmation: values.ulangKataSandi,
        name: values.namaLengkap,
        institusi: values.institusi,
        alamat_institusi: values.alamat,
        provinsi: values.provinsi,
        kota: values.kota,
        kecamatan: values.kecamatan,
        kodepos: values.kodepos,
        hp: values.telepon,
        telp_institusi: values.telp_institusi,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status) {
              toast({
                title: "Profil Berhasil Diperbarui",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            }
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Profil Gagal Diperbarui",
              description: error?.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Box p={4} borderRadius={12} bg={"var(--p500a3)"} mb={4}>
        <SimpleGrid columns={[1, null, 2]} gap={4}>
          <Box>
            <Text mb={1} opacity={0.5}>
              Email
            </Text>
            <Text>{data.email}</Text>
          </Box>

          <Box>
            <Text mb={1} opacity={0.5}>
              Username
            </Text>
            <Text>{data.username}</Text>
          </Box>
        </SimpleGrid>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
          <FormControl isInvalid={formik.errors.namaLengkap ? true : false}>
            <FormLabel>
              Nama Pemohon
              <RequiredForm />
            </FormLabel>

            <Input
              name="namaLengkap"
              onChange={formik.handleChange}
              value={formik.values.namaLengkap}
              placeholder="Masukkan nama pemohon"
            />
            <FormErrorMessage>{formik.errors.namaLengkap}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors.institusi ? true : false}>
            <FormLabel>
              Nama Institusi
              <RequiredForm />
            </FormLabel>

            <Input
              name="institusi"
              onChange={formik.handleChange}
              value={formik.values.institusi}
              placeholder="Masukkan nama institusi"
            />
            <FormErrorMessage>{formik.errors.institusi}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <FormControl isInvalid={formik.errors.alamat ? true : false} mb={4}>
          <FormLabel>
            Alamat Institusi
            <RequiredForm />
          </FormLabel>

          <Textarea
            name="alamat"
            onChange={formik.handleChange}
            value={formik.values.alamat}
            placeholder="Masukkan alamat"
          />
          <FormErrorMessage>{formik.errors.alamat}</FormErrorMessage>
        </FormControl>

        <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
          <FormControl isInvalid={formik.errors.provinsi ? true : false}>
            <FormLabel>
              Provinsi
              <RequiredForm />
            </FormLabel>

            <SelectProvinsi formik={formik} />

            <FormErrorMessage>
              {formik.errors.provinsi as string}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors.kota ? true : false}>
            <FormLabel>
              Kota
              <RequiredForm />
            </FormLabel>

            <SelectKota
              formik={formik}
              provinsiId={formik.values.provinsi?.id}
            />
            <FormErrorMessage>{formik.errors.kota as string}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
          <FormControl isInvalid={formik.errors.kecamatan ? true : false}>
            <FormLabel>
              Kecamatan
              <RequiredForm />
            </FormLabel>

            <SelectKecamatan formik={formik} kotaId={formik.values.kota?.id} />

            <FormErrorMessage>
              {formik.errors.kecamatan as string}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors.kodepos ? true : false}>
            <FormLabel>
              Kodepos
              <RequiredForm />
            </FormLabel>

            <Input
              name="kodepos"
              onChange={formik.handleChange}
              value={formik.values.kodepos}
              placeholder="Masukkan kodepos"
            />
            <FormErrorMessage>{formik.errors.kodepos}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
          <FormControl isInvalid={formik.errors.telepon ? true : false} mb={4}>
            <FormLabel>
              No.Telp
              <RequiredForm />
            </FormLabel>

            <Input
              name="telepon"
              onChange={formik.handleChange}
              value={formik.values.telepon}
              placeholder="Masukkan nomor telepon"
            />
            <FormErrorMessage>{formik.errors.telepon}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={formik.errors.telp_institusi ? true : false}
            mb={4}
          >
            <FormLabel>
              No.Telp Institusi/Fax
              <RequiredForm />
            </FormLabel>

            <Input
              name="telp_institusi"
              onChange={formik.handleChange}
              value={formik.values.telp_institusi}
              placeholder="Masukkan nomor telepon institusi atau Fax"
            />
            <FormErrorMessage>{formik.errors.telp_institusi}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <HStack>
          <Button
            colorScheme="ap"
            ml={"auto"}
            px={12}
            type="submit"
            className="clicky"
            isLoading={loading}
          >
            Save
          </Button>
        </HStack>
      </form>
    </>
  );
}
