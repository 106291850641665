import { create } from "zustand";

type State = {
  fotoSampel: boolean;
  filePendukung: boolean;
};

type Actions = {
  setFotoSampel: (fotoSampel: boolean) => void;
  setFilePendukung: (filePendukung: boolean) => void;
};

const useValidateBerkas = create<State & Actions>((set) => ({
  fotoSampel: false,
  filePendukung: false,
  setFotoSampel: (fotoSampel) => set(() => ({ fotoSampel: fotoSampel })),
  setFilePendukung: (filePendukung) =>
    set(() => ({ filePendukung: filePendukung })),
}));

export default useValidateBerkas;
