import { Button, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import Footer from "../../components/Footer";

export default function MaintenancePage() {
  return (
    <VStack minH={"100vh"} gap={0}>
      <VStack p={8} flex={1} justify={"center"} gap={4}>
        <Image src="/vector/error503.webp" maxW={"360px"} />

        <Text textAlign={"center"} fontSize={32} fontWeight={600}>
          503 Sedang Dalam Pemeliharaan
        </Text>

        <Text textAlign={"center"} mb={4} maxW={"600px"} opacity={0.5}>
          Kami sedang memperbaiki masalah untuk meningkatkan pengalaman Anda.
          Terima kasih atas kesabaran Anda, dan kami akan segera kembali!
        </Text>

        <Button
          w={"100%"}
          maxW={"300px"}
          variant={"outline"}
          colorScheme="ap"
          className="lg-clicky"
          onClick={() => {
            window.history.back();
          }}
          mb={2}
        >
          Back
        </Button>

        <Button
          variant={"unstyled"}
          className="lg-clicky"
          as={Link}
          to={"/"}
          mx={"auto"}
          color={"p.500"}
        >
          Go to Landing Page
        </Button>
      </VStack>

      <VStack bg={"p.500"} w={"100%"} p={8} mt={"auto"}>
        <Container>
          <Footer />
        </Container>
      </VStack>

      <VStack w={"100%"} bg={"p.500"}>
        <Container py={2} borderTop={"1px solid var(--divider)"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={[10, null, 12]}
            opacity={0.5}
          >
            © 2023 powered by{" "}
            <span style={{ fontWeight: 600 }}>Distro Studio</span>
          </Text>
        </Container>
      </VStack>
    </VStack>
  );
}
