import React from "react";
import { Box, HStack, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import SortKelolaProduk from "../../components/SortKelolaProduk";
import TabelKelolaProduk from "../../components/TabelKelolaProduk";
import TambahProdukModal from "../../components/TambahProdukModal";
import SearchProduk from "../../components/SearchProduk";

export default function AdminProduk() {
  const bg = useColorModeValue("white", "dark");

  return (
    <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
      <SimpleGrid columns={[1, 2]} gap={4} mb={4}>
        <SearchProduk />
        <HStack justify={["flex-start", "flex-end"]}>
          <SortKelolaProduk />

          <TambahProdukModal />
        </HStack>
      </SimpleGrid>

      <TabelKelolaProduk />
    </Box>
  );
}
