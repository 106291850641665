import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import { Transaksi } from "../const/types";
import formatDate from "../lib/formatDate";
import { useParams } from "react-router-dom";

export default function InformasiTransaksi() {
  const [data, setData] = useState<Transaksi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);

    const url = `api/transaction/${noreg}/getdetail`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              setData(response.data.data);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg]);

  if (!loading && data) {
    return (
      <Box>
        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            No. Registrasi
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data.id.toString().padStart(3, "0")}</Text>
        </HStack>
        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Jenis
          </Text>

          <Text mx={1}>:</Text>

          <Text>Umum</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Nama Sertifikat
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data.nama_sertifikat}</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Alamat Sertifikat
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data.alamat_sertifikat}</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Tanggal Pendaftaran
          </Text>

          <Text mx={1}>:</Text>

          <Text>{formatDate(data.tgl_transaksi)}</Text>
        </HStack>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
