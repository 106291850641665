import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useCompareValues from "../../lib/useCompareValues";
import clientRequest from "../../const/clientRequest";
import reqWithHeader from "../../const/reqWithHeader";
import ComponentSpinner from "../ComponentSpinner";
import useTrigger from "../../globalState/useTrigger";
import { KontakWa } from "../../const/types";
import TidakAdaData from "../TidakAdaData";
import NoOflinesText from "../NoOflinesText";
import formatNumber from "../../lib/formatNumber";
import useSortKontakWa from "../../globalState/useSortKontakWa";
import useSearchFKontakWa from "../../globalState/useSearchKontakWa";
import AksiUpdateDeleteKontakWa from "./AksiUpdateDeleteKontakWa";

export default function TabelKontakWa() {
  const [initialData, setInitialData] = useState<KontakWa[] | [] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();
  useEffect(() => {
    setLoading(true);
    const url = `api/contact/get`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setInitialData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [trigger]);

  const [data, setData] = useState(initialData ? [...initialData] : null);
  const { sortBy, sortOrder } = useSortKontakWa();
  const compareValues = useCompareValues;
  useEffect(() => {
    if (initialData) {
      const sorted = [...initialData].sort(compareValues(sortBy, sortOrder));
      setData(sorted);
    }
  }, [sortBy, sortOrder, compareValues, initialData]);

  const { searchTerm } = useSearchFKontakWa();
  const filteredData = data?.filter(
    (d) =>
      d.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.id.toString().includes(searchTerm)
  );

  if (!loading && data && data.length > 0) {
    if (filteredData?.length === 0) {
      return <TidakAdaData />;
    } else {
      return (
        <Box
          borderRadius={8}
          border={"1px solid var(--divider3)"}
          overflow={"auto"}
          className="scrollX scrollY"
          // maxH={sw < 770 ? "calc(100vh - 236px)" : "calc(100vh - 200px)"}
        >
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Nama</Th>
                <Th>Nomor WA</Th>
                <Th textAlign={"center"} w={"64px"}>
                  Aksi
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {filteredData?.map((d, i) => (
                <Tr key={i}>
                  <Td w={"50px"}>{formatNumber(i + 1)}</Td>
                  <Td>
                    <NoOflinesText minW={"200px"}>{d.name}</NoOflinesText>
                  </Td>
                  <Td>
                    <NoOflinesText minW={"200px"}>{d.wa}</NoOflinesText>
                  </Td>
                  <Td isNumeric>
                    <AksiUpdateDeleteKontakWa data={d} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    }
  } else if (!loading && data && data.length === 0) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
