import { HStack, Icon, MenuItem, Text } from "@chakra-ui/react";
import { PencilSimpleLine } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";

export default function AksiUpdateMenuButton(props: any) {
  return (
    <MenuItem {...props}>
      <HStack>
        <Icon as={PencilSimpleLine} fontSize={iconSize} />
        <Text>Ubah</Text>
      </HStack>
    </MenuItem>
  );
}
