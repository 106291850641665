import { Box, Icon, Text, VStack } from "@chakra-ui/react";
import { Flask } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { Transaksi } from "../const/types";

interface Props {
  data: Transaksi;
}

export default function LihatLhuDetailTransaksi({ data }: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!data?.is_quesioner) {
      navigate(`/customer/kuisioner/${data.id}`);
    } else {
      window.open(
        `${process.env.REACT_APP_VPS_DOMAIN}${data.berkaslhu?.[0]?.path}`,
        "_blank"
      );
    }
  };

  return (
    <VStack
      gap={0}
      borderRadius={8}
      bg={"var(--divider)"}
      p={2}
      border={"1px solid var(--divider3)"}
      align={"center"}
      onClick={handleClick}
      cursor={"pointer"}
    >
      <Icon as={Flask} fontSize={100} weight="light" my={2} />
      <Box px={2}>
        <Text textAlign={"center"}>LHU</Text>
      </Box>
    </VStack>
  );
}
