import { ChakraProvider } from "@chakra-ui/react";
import { globalTheme } from "./chakraTheme/globalTheme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import "./globalStyle.css";
import CustomerRoutes from "./routes/CustomerRoutes";
import MissingPage from "./pages/errorsPage/MissingPage";
import AdminRoutes from "./routes/AdminRoutes";
import InputNewPassword from "./pages/InputNewPassword";
import InternalServerErrorPage from "./pages/errorsPage/InternalServerErrorPage";
import MaintenancePage from "./pages/errorsPage/MaintenancePage";
import Policy from "./pages/Policy";
import Faqs from "./pages/Faqs";

export const App = () => (
  <ChakraProvider theme={globalTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/customer/*" element={<CustomerRoutes />} />

        <Route path="/admin/*" element={<AdminRoutes />} />

        <Route
          path="/reset-password/:resettoken"
          element={<InputNewPassword />}
        />

        <Route path="/servererror" element={<InternalServerErrorPage />} />

        <Route path="/maintenance" element={<MaintenancePage />} />

        <Route path="/policy" element={<Policy />} />

        <Route path="/faqs" element={<Faqs />} />

        <Route path="*" element={<MissingPage />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);
