import React from "react";
import UpdateProdukModal from "./UpdateProdukModal";
import DeleteProdukModal from "./DeleteProdukModal";
import ThreeDotsMenu from "./ThreeDotsMenu";
import { Product } from "../const/types";

type Props = {
  data: Product;
};

export default function AksiUpdateDeleteProdukModal({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateProdukModal data={data} />

      <DeleteProdukModal id={data.id} />
    </ThreeDotsMenu>
  );
}
