import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBackOnClose from "../lib/useBackOnClose";
import { checkBoxTextMt } from "../const/sizes";
import RequiredForm from "./RequiredForm";
import * as yup from "yup";
import { useFormik } from "formik";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

export default function PendaftaranPengujianModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const initialRef = useRef(null);

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      namaSertifikatUji: "",
      alamatSertifikatUji: "",
      disclaimer: false,
    },

    validationSchema: yup.object().shape({
      namaSertifikatUji: yup
        .string()
        .required("Nama Sertifikat Hasil Uji harus diisi"),
      alamatSertifikatUji: yup
        .string()
        .required("Alamat Sertifikat Hasil Uji harus diisi"),
      disclaimer: yup
        .boolean()
        .oneOf([true], "Anda harus menyetujui Syarat dan Ketentuan")
        .required("Anda harus menyetujui Syarat dan Ketentuan"),
    }),

    onSubmit: (values, { resetForm }) => {
      const url = "api/transaksi/step1";
      const data = {
        nama_sertifikat: values.namaSertifikatUji,
        alamat_sertifikat: values.alamatSertifikatUji,
        disclaimer: values.disclaimer,
      };

      setLoading(true);

      clientRequest.then(() => {
        reqWithHeader
          .post(url, data)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
              const noreg = response.data.data.id;
              navigate(
                `/customer/pengujian/pendaftaran-pengujian/${noreg}/pilih-metode-uji`
              );
            } else {
              toast({
                title: "Error",
                description: "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            if (error?.response?.status === 403) {
              navigate(`/customer/profil`);
              toast({
                title: "Lengkapi akun Anda",
                description:
                  "Pendaftaarn pengujian hanya bisa dilakukan setelah data Anda lengkap",
                status: "warning",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  return (
    <>
      <Button
        colorScheme="ap"
        className="lg-clicky btn-ap"
        mb={6}
        onClick={onOpen}
      >
        Pendaftaran Pengujian Online
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        initialFocusRef={initialRef}
        size={"lg"}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader lineHeight={1.2}>
            Form Pendaftaran Pengujian Online
          </ModalHeader>

          <ModalBody>
            <form id="formPendaftaranPengujian" onSubmit={formik.handleSubmit}>
              <FormControl
                mb={4}
                isInvalid={formik.errors.namaSertifikatUji ? true : false}
              >
                <FormLabel>
                  Nama Sertifikat Hasil Uji
                  <RequiredForm />
                </FormLabel>
                <Input
                  ref={initialRef}
                  name="namaSertifikatUji"
                  placeholder="Masukan nama anda"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.namaSertifikatUji}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.alamatSertifikatUji ? true : false}
              >
                <FormLabel>
                  Alamat Sertifikat Hasil Uji
                  <RequiredForm />
                </FormLabel>
                <Textarea
                  name="alamatSertifikatUji"
                  placeholder="Masukan alamat anda"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors.alamatSertifikatUji}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.disclaimer ? true : false}>
                <Checkbox
                  isInvalid={formik.errors.disclaimer ? true : false}
                  alignItems={"flex-start"}
                  colorScheme="ap"
                  gap={1}
                  isChecked={formik.values.disclaimer}
                  onChange={() => {
                    formik.setFieldValue(
                      "disclaimer",
                      !formik.values.disclaimer
                    );
                  }}
                >
                  <Text mt={checkBoxTextMt} fontSize={[12, null, 14]}>
                    Saya dengan ini menyatakan setuju dengan{" "}
                    <ChakraLink
                      color="p.500"
                      href="/policy"
                      fontSize={["12px !important", null, "14px !important"]}
                      isExternal
                      fontWeight={600}
                    >
                      syarat dan ketentuan
                    </ChakraLink>{" "}
                    yang berlaku di LPPT POLTEKKES Kemenkes Semarang
                  </Text>
                </Checkbox>
                <FormErrorMessage>{formik.errors.disclaimer}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="ap"
              className="lg-clicky btn-ap"
              type="submit"
              form="formPendaftaranPengujian"
              isDisabled={!formik.values.disclaimer}
              isLoading={loading}
            >
              Simpan & Lanjutkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
