import { Button } from "@chakra-ui/react";
import React from "react";
import { Transaksi } from "../const/types";
import { useNavigate } from "react-router-dom";
import download from "../lib/download";

interface Props {
  data: Transaksi;
}

export default function DownloadLhuButton({ data }: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!data?.is_quesioner) {
      navigate(`/customer/kuisioner/${data.id}`);
    } else {
      download(
        `${process.env.REACT_APP_BASE_URL}api/berkas/download/${data.id}/berkaslhu`,
        "Berkas LHU",
        data
      );
    }
  };

  return (
    <Button
      colorScheme="ap"
      className="lg-clicky btn-ap"
      mb={3}
      onClick={handleClick}
    >
      Unduh
    </Button>
  );
}
