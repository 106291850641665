import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { PencilSimpleLine } from "@phosphor-icons/react";
import React from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { Customer } from "../const/types";
import { useFormik } from "formik";
import * as yup from "yup";

type Props = {
  data: Customer;
};

export default function AdminUpdateCustomer({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      id_pelanggan: data.id_customer,
    },

    validationSchema: yup.object().shape({
      id_pelanggan: yup.string().required("Id pelanggan harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values);

      //TODO pasang api update id customer
    },
  });

  return (
    <>
      <Button
        leftIcon={<Icon as={PencilSimpleLine} />}
        className="btn-solid"
        onClick={onOpen}
      >
        Ubah
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Ubah Customer
          </ModalHeader>

          <ModalBody>
            <form id="updateCustomerForm" onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={formik.errors.id_pelanggan ? true : false}
              >
                <FormLabel>ID Pelanggan</FormLabel>
                <Input
                  name={"id_pelanggan"}
                  placeholder="Masukkan id pengguna"
                  onChange={formik.handleChange}
                  value={formik.values.id_pelanggan}
                />
                <FormErrorMessage>
                  {formik.errors.id_pelanggan}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="ap" className="btn-ap">
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
