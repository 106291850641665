import React from "react";
import { useColorModeValue, VStack } from "@chakra-ui/react";
import TabelFileTemplate from "../../components/TabelFileTemplate";

export default function AdminFileTemplate() {
  const bg = useColorModeValue("white", "dark");

  return (
    <VStack flex={1} p={[4, null, 6]} bg={bg} borderRadius={16}>
      <TabelFileTemplate />
    </VStack>
  );
}
