import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { iconSize } from "../const/sizes";
import { Plus } from "@phosphor-icons/react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import { KategoriProduk } from "../const/types";
import RequiredForm from "./RequiredForm";
import reqWithHeader from "../const/reqWithHeader";
import clientRequest from "../const/clientRequest";
import ComponentSpinner from "./ComponentSpinner";
import useTrigger from "../globalState/useTrigger";
import useFormatNumber from "../lib/useFormatNumber";
import useReverseFormatNumber from "../lib/useReverseFormatNumber";
import useScreenWidth from "../lib/useGetScreenWidth";

export default function TambahProdukModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const sw = useScreenWidth();

  const [kategori, setKategori] = useState<KategoriProduk[] | null>(null);
  const [loadingKategori, setLoadingKategori] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  useEffect(() => {
    if (isOpen && !kategori) {
      setLoadingKategori(true);
      const url = `api/product/kategori`;
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.data.status === 200) {
              setKategori(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Terjadi Kesalahan Silahkan Refresh",
              description: "Maaf terjadi kesalahan sistem",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoadingKategori(false);
          });
      });
    }
  }, [isOpen, kategori, trigger, toast]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      kode: "",
      namaProduk: "",
      kategori_id: "",
      harga: 0,
      deskripsi: "",
    },

    validationSchema: yup.object().shape({
      kode: yup.string().required("Kode Produk harus diisi"),
      namaProduk: yup.string().required("Nama Produk harus diisi"),
      kategori_id: yup.string().required("Kategori Produk harus diisi"),
      harga: yup
        .number()
        .min(1, "Harga Produk harus lebih dari 0")
        .required("Harga Produk harus diisi"),
      deskripsi: yup.string().required("Deskripsi Produk harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/product/store`;
      const payload = {
        kode: values.kode,
        name: values.namaProduk,
        price: values.harga,
        kategori_id: values.kategori_id,
        deskripsi: values.deskripsi,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              resetForm();
              setTrigger(!trigger);
              toast({
                title: "Produk Berhasil Ditambahkan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Produk Gagal Ditambahkan",
                description:
                  response.data.message.name[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const fn = useFormatNumber;
  const rfn = useReverseFormatNumber;

  return (
    <>
      <Button
        flexShrink={0}
        leftIcon={<Icon as={Plus} fontSize={iconSize} />}
        pl={3}
        pr={sw < 874 ? 1 : 4}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
      >
        <Text display={sw < 874 ? "none" : "block"}>Tambah Produk</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Tambah Produk</ModalHeader>

          {!loadingKategori && (
            <>
              <ModalBody>
                <form id="tambahProdukForm" onSubmit={formik.handleSubmit}>
                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.kode ? true : false}
                  >
                    <FormLabel>
                      Kode
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="kode"
                      placeholder="Masukkan kode produk"
                      onChange={formik.handleChange}
                      value={formik.values.kode}
                    />
                    <FormErrorMessage>{formik.errors.kode}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.namaProduk ? true : false}
                  >
                    <FormLabel>
                      Nama Produk
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="namaProduk"
                      placeholder="Masukkan nama produk"
                      onChange={formik.handleChange}
                      value={formik.values.namaProduk}
                    />
                    <FormErrorMessage>
                      {formik.errors.namaProduk}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.kategori_id ? true : false}
                  >
                    <FormLabel>
                      Kategori Produk
                      <RequiredForm />
                    </FormLabel>
                    <Select
                      name="kategori_id"
                      placeholder="Pilih kategori produk"
                      onChange={formik.handleChange}
                      value={formik.values.kategori_id}
                    >
                      {kategori?.map((k, i) => (
                        <option key={i} value={k.id}>
                          {k.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {formik.errors.kategori_id}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.harga ? true : false}
                  >
                    <FormLabel>
                      Harga
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      name="harga"
                      placeholder="Masukkan harga produk"
                      onChange={(e) => {
                        formik.setFieldValue("harga", rfn(e.target.value));
                      }}
                      value={fn(formik.values.harga)}
                    />
                    <FormErrorMessage>{formik.errors.harga}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={formik.errors.deskripsi ? true : false}
                  >
                    <FormLabel>
                      Deskripsi Produk
                      <RequiredForm />
                    </FormLabel>
                    <Textarea
                      name="deskripsi"
                      placeholder="Masukkan deskripsi produk"
                      onChange={formik.handleChange}
                      value={formik.values.deskripsi}
                    />
                    <FormErrorMessage>
                      {formik.errors.deskripsi}
                    </FormErrorMessage>
                  </FormControl>
                </form>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  form={"tambahProdukForm"}
                  colorScheme="ap"
                  className="lg-clicky btn-ap"
                  isLoading={loading}
                >
                  Tambahkan
                </Button>
              </ModalFooter>
            </>
          )}

          {loadingKategori && <ComponentSpinner h={"calc(376px + 88px)"} />}
        </ModalContent>
      </Modal>
    </>
  );
}
