import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import FileInput from "./FileInput";
import useTrigger from "../globalState/useTrigger";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useFormatNumber from "../lib/useFormatNumber";
import useReverseFormatNumber from "../lib/useReverseFormatNumber";

type Props = {
  noreg: number;
};

export default function InputKontrakTagihanModal({ noreg }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();
  const fn = useFormatNumber;
  const rfn = useReverseFormatNumber;
  const tagihanTambahanHelperTextColor = useColorModeValue(
    "red.500",
    "red.300"
  );

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      fileKontrak: [] as File[] | null,
      tagihanTambahan: "",
    },

    validationSchema: yup.object().shape({
      fileKontrak: yup
        .array()
        .min(1, "Foto Sampel harus diunggah")
        .required("File kontrak harus diunggah")
        .test(
          "fileType",
          "Hanya file PDF dan DOC/DOCX (ms word) yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "application/pdf" ||
                file.type === "application/msword" ||
                file.type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            )
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      tagihanTambahan: yup.number().required("Tagihan Tambahan harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaction/${noreg}/upload/berkaskontrakadmin`;
      const payload = new FormData();
      if (values.fileKontrak) {
        values.fileKontrak.forEach((file) => {
          payload.append("file", file);
        });
      }

      payload.append("biaya", values.tagihanTambahan);

      console.log(payload);

      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setTrigger(!trigger);
              toast.closeAll();
              toast({
                title: "Input FIle Kontrak berhasil",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Input File Kontrak Gagal",
                description: "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Input File Kontrak Gagal",
              description: "Maaf terjadi kesalahan",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  // const fn = useFormatNumber;
  // const rfn = useReverseFormatNumber;
  // const tagihanTambahanHelperTextColor = useColorModeValue(
  //   "red.500",
  //   "red.300"
  // );

  return (
    <>
      <Button
        w={"100%"}
        colorScheme={"ap"}
        className="lg-clicky"
        onClick={onOpen}
        id={`Berkas Diverifikasi - ${noreg}`}
      >
        Input Kontrak & Tagihan
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Masukkan File Kontrak & Tagihan</ModalHeader>

          <ModalBody>
            <Alert
              className="alert"
              variant={"left-accent"}
              colorScheme="orange"
              mb={6}
            >
              <Box>
                <AlertTitle fontSize={[18, null, 20]} color={"orange"}>
                  PERHATIAN!!
                </AlertTitle>
                <AlertDescription>
                  Format berkas kontrak berupa{" "}
                  <b>.pdf, .doc, .docx maks. 1 MB</b>
                </AlertDescription>
              </Box>
            </Alert>

            <form id="kontrakTagihanForm" onSubmit={formik.handleSubmit}>
              <FormControl
                mb={4}
                isInvalid={formik.errors.fileKontrak ? true : false}
              >
                <FormLabel>
                  Unggah File Kontrak
                  <RequiredForm />
                </FormLabel>
                <FileInput
                  name="fileKontrak"
                  formik={formik}
                  accept={".pdf, .doc, .docx"}
                />
                <FormErrorMessage>{formik.errors.fileKontrak}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.errors.tagihanTambahan ? true : false}
              >
                <FormLabel>Tagihan Ekstra</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <Text>Rp</Text>
                  </InputLeftElement>
                  <Input
                    name="tagihanTambahan"
                    placeholder="Masukkan tagihan ekstra"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        formik.setFieldValue("tagihanTambahan", undefined);
                      } else {
                        formik.setFieldValue(
                          "tagihanTambahan",
                          rfn(e.target.value)
                        );
                      }
                    }}
                    value={
                      formik.values.tagihanTambahan
                        ? fn(parseInt(formik.values.tagihanTambahan))
                        : parseInt(formik.values.tagihanTambahan) === 0
                        ? "0"
                        : ""
                    }
                  />
                </InputGroup>
                <FormHelperText color={tagihanTambahanHelperTextColor}>
                  *jika tidak ada tagihan ekstra, masukan 0
                </FormHelperText>
                <FormErrorMessage>
                  {formik.errors.tagihanTambahan}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="kontrakTagihanForm"
              className="lg-clicky btn-ap"
              colorScheme="ap"
              isLoading={loading}
            >
              Simpan & Kirim
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
