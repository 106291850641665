import { create } from "zustand";

type State = {
  searchTerm: string;
};

type Actions = {
  setSearchTerm: (selectedFotoSampel: State["searchTerm"]) => void;
};

const useSearchTransaksi = create<State & Actions>((set) => ({
  searchTerm: "",
  setSearchTerm: (searchTerm) => set(() => ({ searchTerm: searchTerm })),
}));

export default useSearchTransaksi;
