import React from "react";
import ThreeDotsMenu from "./ThreeDotsMenu";
import { BiayaTambahan } from "../const/types";
import UpdateBiayaTambahanModal from "./UpdateBiayaTambahanModal";
import DeleteBiayaTambahanModal from "./DeleteBiayaTambahanModal";

type Props = {
  data: BiayaTambahan;
};

export default function AksiUpdateDeleteBiayaTambahan({ data }: Props) {
  return (
    <ThreeDotsMenu>
      <UpdateBiayaTambahanModal data={data} />

      <DeleteBiayaTambahanModal id={data.id} />
    </ThreeDotsMenu>
  );
}
