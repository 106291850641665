import {
  Avatar,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  Wrap,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AdminNav from "./AdminNav";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import useScreenWidth from "../lib/useGetScreenWidth";
import { ArrowClockwise, CaretDown, SignOut } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useNavigate } from "react-router-dom";
import SedangKeluar from "./SedangKeluar";
import LogoLoader from "./LogoLoader";
import { Cookies } from "typescript-cookie";

type Props = {
  children: any;
  active: string[];
};

export default function AdminContainer({ children, active }: Props) {
  const contentBg = useColorModeValue("var(--divider)", "#1f1f1f");
  const sw = useScreenWidth();
  const navigate = useNavigate();
  const toast = useToast();
  const buttonBg = useColorModeValue("red.500", "red.200");
  const buttonBgHover = useColorModeValue("red.600", "red.300");
  const buttonBgActive = useColorModeValue("red.700", "red.400");
  const buttonColor = useColorModeValue("white", "black");
  const [loadingKeluar, setLoadingKeluar] = useState<boolean>(false);
  const handleSignout = () => {
    setLoadingKeluar(true);
    const url = `api/logout`;
    clientRequest.then(() => {
      reqWithHeader
        .post(url)
        .then((response) => {
          if (response.data.status === 200) {
            Cookies.remove("userData");
            navigate("/");
            toast({
              title: "Berhasil Keluar",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  const [page, setPage] = useState<any>(<LogoLoader />);

  useEffect(() => {
    setPage(<LogoLoader />);

    const url = "api/user/logindata";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data.data.role === 2 ||
              response.data.data.role === "2"
            ) {
              setPage(children);
            } else {
              toast({
                title: "Tidak Ada Akses",
                description: "Anda tidak terdaftar untuk autentikasi ini",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
              Cookies.remove("userData");
              navigate("/");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Tidak Ada Akses",
            description: "Silahkan masuk terlebih dahulu",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
          Cookies.remove("userData");
          navigate("/");
        });
    });
  }, [children, navigate, toast]);

  return (
    <>
      {loadingKeluar && <SedangKeluar />}

      <HStack gap={0} minH={"100vh"} align={"stretch"} bg={contentBg}>
        <AdminNav active={active} />

        <VStack
          align={"stretch"}
          flex={1}
          pl={sw < 770 ? "" : "80px"}
          pb={sw < 770 ? "60px" : ""}
          w={"100%"}
          // maxW={sw < 770 ? "100vw" : "calc(100vw - 95px)"}
        >
          <VStack
            px={[4, null, 6]}
            flex={1}
            w={"100%"}
            gap={0}
            align={"stretch"}
            pb={[4, null, 6]}
          >
            <Wrap py={4} justify={"space-between"} spacing={4}>
              <Text fontSize={[20, null, 24]} fontWeight={600} noOfLines={1}>
                {active[active.length - 1]}
              </Text>

              <HStack>
                <IconButton
                  aria-label="refreshButton"
                  icon={<Icon as={ArrowClockwise} fontSize={iconSize} />}
                  className="btn-solid"
                  onClick={() => {
                    window.location.reload();
                  }}
                />

                <ColorModeSwitcher fontSize={20} className="btn-solid" ml={0} />

                <Menu>
                  <MenuButton as={Button} px={0} className="btn-solid">
                    <HStack borderRadius={8} p={2} cursor={"pointer"}>
                      <Icon as={CaretDown} fontSize={iconSize} />

                      <Text>Admin</Text>

                      <Avatar name="Jolitos Kurniawan" size={"xs"} />
                    </HStack>
                  </MenuButton>

                  <MenuList minW={"126px"} border={"none"}>
                    <MenuItem
                      bg={buttonBg}
                      color={buttonColor}
                      _hover={{ bg: buttonBgHover }}
                      _active={{ bg: buttonBgActive }}
                      onClick={handleSignout}
                    >
                      <HStack>
                        <Icon as={SignOut} fontSize={iconSize} />
                        <Text>Keluar</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </Wrap>

            {page}
          </VStack>
        </VStack>
      </HStack>
    </>
  );
}
