import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import * as yup from "yup";
import { useFormik } from "formik";
import RequiredForm from "./RequiredForm";
import { Penguji } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";
import formatDate from "../lib/formatDate";
import FileInput from "./FileInput";

type Props = {
  noreg: number;
};

export default function InputTanggalPengujianModal({ noreg }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };
  const [penguji, setPenguji] = useState<Penguji[] | null>(null);
  const [loadingPenguji, setLoadingPenguji] = useState<boolean>(false);
  useEffect(() => {
    if (isOpen) {
      setLoadingPenguji(true);
      const url = `api/penguji/getall`;
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.data.status === 200) {
              setPenguji(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoadingPenguji(false);
          });
      });
    }
  }, [isOpen]);

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      tanggalPengujian: "",
      penguji: "",
      file: [] as File[] | null,
    },

    validationSchema: yup.object().shape({
      tanggalPengujian: yup.string().required("Tanggal Pengujian harus diisi"),
      penguji: yup.string().required("Penguji harus diisi"),
      file: yup.array().min(1, "File harus diisi").required("File harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaction/${noreg}/uptglpengujian`;
      const payload = new FormData();
      payload.append("tgl_pengujian", formatDate(values.tanggalPengujian));
      payload.append("penguji_id", values.penguji);
      if (values.file) {
        values.file.forEach((file) => {
          payload.append("file", file);
        });
      }
      console.log(values);
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setTrigger(!trigger);
              toast.closeAll();
              toast({
                title: response.data.message,
                description: response.data.data.respon,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Gagal Input Tanggal Pengujian",
              description: "Maaf terjadi kesalahan",
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        w={"100%"}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
        id={`Berkas Lanjutan Diverifikasi - ${noreg}`}
      >
        Masukan Detail Pengujian{" "}
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Masukkan Tanggal Pengujian</ModalHeader>

          {!loadingPenguji && penguji ? (
            <>
              <ModalBody>
                <form id="verifikasiBerkasForm" onSubmit={formik.handleSubmit}>
                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.tanggalPengujian ? true : false}
                  >
                    <FormLabel>
                      Tanggal Pengujian
                      <RequiredForm />
                    </FormLabel>
                    <Input
                      type="date"
                      name="tangalPengujian"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "tanggalPengujian",
                          new Date(e.target.value)
                        );
                      }}
                    />
                    <FormErrorMessage>
                      {formik.errors.tanggalPengujian}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mb={4}
                    isInvalid={formik.errors.penguji ? true : false}
                  >
                    <FormLabel>
                      Penguji
                      <RequiredForm />
                    </FormLabel>
                    <Select
                      name="penguji"
                      value={formik.values.penguji}
                      onChange={formik.handleChange}
                      placeholder="Pilih penguji"
                    >
                      {penguji?.map((p, i) => (
                        <option key={i} value={p.id}>
                          {p.nama}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{formik.errors.penguji}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formik.errors.file ? true : false}>
                    <FormLabel>
                      Pemberitahuan Penjadwalan
                      <RequiredForm />
                    </FormLabel>
                    <FileInput formik={formik} name="file" />
                    <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
                  </FormControl>
                </form>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  form="verifikasiBerkasForm"
                  className="lg-clicky btn-ap"
                  colorScheme="ap"
                  isLoading={loading}
                >
                  Simpan & Kirim
                </Button>
              </ModalFooter>
            </>
          ) : (
            <VStack h={"calc(159px + 87px)"} justify={"center"}>
              <Spinner />
            </VStack>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
