import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import DividerFullHorizontal from "./DividerFullHorizontal";
import useFormatNumber from "../lib/useFormatNumber";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import clientRequest from "../const/clientRequest";
import { Product } from "../const/types";
import ComponentSpinner from "./ComponentSpinner";
import reqWithHeader from "../const/reqWithHeader";
import MetodeUjiListPilihButton from "./MetodeUjiListPilihButton";
import TidakAdaData from "./TidakAdaData";

export default function MetodeUjiList() {
  const [data, setData] = useState<Product[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const filteredData = data?.filter((d) =>
    d.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);

    const url = "api/product/getall";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          console.log("all products", response.data.data);
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  const fn = useFormatNumber;

  if (!loading && data) {
    return (
      <>
        <HStack gap={4} mb={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={MagnifyingGlass} fontSize={iconSize} />
            </InputLeftElement>
            <Input
              placeholder="Cari metode uji"
              maxW={"352px"}
              className="filled"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
          </InputGroup>

          {/* <Button
            variant={"outline"}
            colorScheme="ap"
            leftIcon={<Icon as={Funnel} fontSize={iconSize} />}
            pl={"14px"}
            className="lg-clicky"
          >
            Filter
          </Button> */}
        </HStack>

        {filteredData?.length === 0 ? (
          <TidakAdaData />
        ) : (
          <SimpleGrid columns={[1, 2, 3]} gap={4}>
            {filteredData?.map((metode, i) => (
              <VStack
                key={i}
                gap={0}
                align={"flex-start"}
                // justify={"space-between"}
                p={4}
                borderRadius={8}
                border={"1px solid var(--divider3)"}
                bg={"var(--divider)"}
              >
                <Box>
                  <Tooltip label={metode.name}>
                    <Text noOfLines={2} fontWeight={600}>
                      {metode.name}
                    </Text>
                  </Tooltip>
                  <Text opacity={0.5} fontSize={[12, null, 14]} mb={4}>
                    {metode.kategori}
                  </Text>
                </Box>
                <Text fontSize={[12, null, 14]}>{metode.deskripsi}</Text>
                <Box w={"100%"} mt={"auto"}>
                  <DividerFullHorizontal my={4} />

                  <SimpleGrid columns={[1, 2, 2]} gap={4}>
                    <Text
                      fontWeight={600}
                      alignSelf={"center"}
                      whiteSpace={"nowrap"}
                    >
                      {`Rp ${fn(metode.price)}`}
                    </Text>

                    <MetodeUjiListPilihButton productId={metode.id} />
                  </SimpleGrid>
                </Box>
                <Text fontSize={14} opacity={0.6} mt={4} fontStyle={"italic"}>
                  *biaya belum termasuk akomodasi
                </Text>
              </VStack>
            ))}
          </SimpleGrid>
        )}
      </>
    );
  } else {
    return <ComponentSpinner />;
  }
}
