import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

type Props = { productId: number };

export default function MetodeUjiListPilihButton({ productId }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { noreg } = useParams();
  const navigate = useNavigate();
  const handlePilihMetodeUji = () => {
    setLoading(true);

    const url = `api/transaksi/${noreg}/chooseproduct`;
    const data = {
      product_id: productId,
    };
    clientRequest.then(() => {
      reqWithHeader
        .post(url, data)
        .then((response) => {
          console.log(response.data);
          navigate(
            `/customer/pengujian/pendaftaran-pengujian/${noreg}/unggah-berkas`
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Button
      colorScheme={"ap"}
      className="lg-clicky"
      onClick={handlePilihMetodeUji}
      w={["100%", "fit-content", null]}
      justifySelf={"flex-end"}
      isLoading={loading}
    >
      Pilih
    </Button>
  );
}
