import React, { useEffect, useState } from "react";
import TableContainer from "./TableContainer";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import useFormatNumber from "../lib/useFormatNumber";
import CustomerAksiPengujian from "./CustomerAksiPengujian";
import DetailTransaksiModal from "./DetailTransaksiModal";
import ComponentSpinner from "./ComponentSpinner";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { Transaksi } from "../const/types";
import formatDate from "../lib/formatDate";
import useTrigger from "../globalState/useTrigger";
import TidakAdaData from "./TidakAdaData";
import StatusModal from "./StatusModal";

export default function TabelRiwayatPengujian() {
  const [data, setData] = useState<Transaksi[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();

  useEffect(() => {
    setLoading(true);
    const url = "api/user/transaksi";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [trigger]);

  const fn = useFormatNumber;

  if (!loading && data) {
    return (
      <TableContainer>
        {data.length > 0 ? (
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th isNumeric>No</Th>
                <Th>No.Registrasi</Th>
                <Th whiteSpace={"nowrap"}>Tanggal Order</Th>
                <Th>Nama</Th>
                <Th whiteSpace={"nowrap"} isNumeric>
                  Tagihan
                </Th>
                <Th whiteSpace={"nowrap"} textAlign={"center"}>
                  Status
                </Th>
                <Th whiteSpace={"nowrap"} textAlign={"center"}>
                  Aksi
                </Th>
                <Th isNumeric></Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.map((d, i) => (
                <Tr key={i}>
                  <Td isNumeric>{i + 1}</Td>
                  <Td>{d.id.toString().padStart(3, "0")}</Td>
                  <Td whiteSpace={"nowrap"}>{formatDate(d.tgl_transaksi)}</Td>
                  <Td whiteSpace={"nowrap"}>{d.nama_sertifikat}</Td>
                  <Td
                    textAlign={d.tagihan !== null ? "right" : "center"}
                    whiteSpace={"nowrap"}
                  >
                    {d.tagihan !== null ? `Rp ${fn(parseInt(d.tagihan))}` : "-"}
                  </Td>
                  <Td textAlign={"center"}>
                    <StatusModal data={d} />
                  </Td>
                  <Td textAlign={"center"}>
                    <CustomerAksiPengujian data={d} />
                  </Td>
                  <Td isNumeric w={"40px"}>
                    <DetailTransaksiModal noreg={d.id} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <TidakAdaData />
        )}
      </TableContainer>
    );
  } else {
    return <ComponentSpinner />;
  }
}
