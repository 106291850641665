import React from "react";
import { Route, Routes } from "react-router-dom";
import MissingPage from "../pages/errorsPage/MissingPage";
import AdminDashboards from "../pages/admin/AdminDashboards";
import AdminKelolaProduk from "../pages/admin/AdminKelolaProduk";
import AdminTransaksi from "../pages/admin/AdminTransaksi";
import AdminKategoriProduk from "../pages/admin/AdminKategoriProduk";
import AdminCustomer from "../pages/admin/AdminCustomer";
import AdminPenguji from "../pages/admin/AdminPenguji";
import AdminFileTemplate from "../pages/admin/AdminFileTemplate";
import AdminBiayaTambahan from "../pages/admin/AdminBiayaTambahan";
import AdminKuisioner from "../pages/admin/AdminKuisioner";
import AdminContainer from "../components/AdminContainer";
import AdminFaqs from "../pages/admin/AdminFaqs";
import AdminKontakWa from "../pages/admin/AdminKontakWa";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminDashboards />} />
      <Route
        path="/transaksi"
        element={
          <AdminContainer active={["Transaksi"]}>
            <AdminTransaksi />
          </AdminContainer>
        }
      />
      <Route
        path="/kategori-produk"
        element={
          <AdminContainer active={["Produk", "Kategori Produk"]}>
            <AdminKategoriProduk />
          </AdminContainer>
        }
      />
      <Route
        path="/kelola-produk"
        element={
          <AdminContainer active={["Produk", "Kelola Produk"]}>
            <AdminKelolaProduk />
          </AdminContainer>
        }
      />
      <Route
        path="/pengguna-customer"
        element={
          <AdminContainer active={["Pengguna", "Customer"]}>
            <AdminCustomer />
          </AdminContainer>
        }
      />
      <Route
        path="/pengguna-penguji"
        element={
          <AdminContainer active={["Pengguna", "Penguji"]}>
            <AdminPenguji />
          </AdminContainer>
        }
      />
      ``
      <Route
        path="/file-template"
        element={
          <AdminContainer active={["Produk", "File Template"]}>
            <AdminFileTemplate />
          </AdminContainer>
        }
      />
      <Route
        path="/biaya-tambahan"
        element={
          <AdminContainer active={["Biaya Tambahan"]}>
            <AdminBiayaTambahan />
          </AdminContainer>
        }
      />
      <Route
        path="/kuisioner"
        element={
          <AdminContainer active={["Produk", "Kuisioner"]}>
            <AdminKuisioner />
          </AdminContainer>
        }
      />
      <Route
        path="/faqs"
        element={
          <AdminContainer active={["Konten", "FAQs"]}>
            <AdminFaqs />
          </AdminContainer>
        }
      />
      <Route
        path="/kontakwa"
        element={
          <AdminContainer active={["Konten", "Kontak WA"]}>
            <AdminKontakWa />
          </AdminContainer>
        }
      />
      <Route path="*" element={<MissingPage />} />
    </Routes>
  );
}
