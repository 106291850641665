import {
  Center,
  Checkbox,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ComponentSpinner from "./ComponentSpinner";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useParams } from "react-router-dom";
import useUnggahBerkasTriggerGetFotoSampel from "../globalState/useTrigger";
import { FilePendukung } from "../const/types";
import useValidateBerkas from "../globalState/useValidateBerkas";
import { File } from "@phosphor-icons/react";
import useSelectedFilePendukung from "../globalState/useSelectedFilePendukung";

export default function FilePendukungList() {
  const [data, setData] = useState<FilePendukung[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();
  const { trigger } = useUnggahBerkasTriggerGetFotoSampel();
  const { setFilePendukung } = useValidateBerkas();

  const { selectedFilePendukung, setSelectedFilePendukung } =
    useSelectedFilePendukung();

  const handleCheckboxChange = (imgId: number) => {
    let res: number[] = [];
    if (!selectedFilePendukung.includes(imgId)) {
      res = [...(selectedFilePendukung as number[]), imgId];
    } else {
      res = selectedFilePendukung.filter((id) => id !== imgId);
    }
    setSelectedFilePendukung(res);
  };

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/berkaspendukung`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            console.log(response.data);
            if (response.data.status === 200) {
              setData(response.data.data);
              if (response.data.data.length > 0) {
                setFilePendukung(true);
              } else {
                setFilePendukung(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg, trigger, setFilePendukung]);

  if (!loading && data && data?.length > 0) {
    return (
      <SimpleGrid columns={[2, 3, null, 4]} gap={4}>
        {data.map((file, i) => (
          <Checkbox
            key={i}
            colorScheme="ap"
            className="checkboxFilePendukung"
            onChange={() => handleCheckboxChange(file.id)}
            justifyContent={"center"}
            borderRadius={8}
            border={"1px solid var(--divider3)"}
            bg={"var(--divider)"}
            h={"200px"}
          >
            <VStack gap={0} align={"center"} pr={2}>
              <Icon as={File} fontSize={100} weight="thin" mb={2} />

              <Text noOfLines={1} px={4} textAlign={"center"}>
                {file.name}
              </Text>
              <Text opacity={0.5} noOfLines={1}>
                {file.size}
              </Text>
            </VStack>
          </Checkbox>
        ))}
      </SimpleGrid>
    );
  }

  if ((!loading && !data) || (data && data.length <= 0)) {
    return (
      <Center p={5} flexDir={"column"}>
        <Image src="/vector/file.webp" h={"250px"} />
        <Text textAlign={"center"} fontWeight={500} maxW={"400px"}>
          Maaf, Saat Ini Anda Belum Memiliki File Pendukung yang Tersedia
        </Text>
      </Center>
    );
  }

  return <ComponentSpinner h={"300px"} />;
}
