import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  useDisclosure,
  Link as ChakraLink,
  ModalFooter,
  Spinner,
  VStack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useFormatNumber from "../lib/useFormatNumber";
import DividerFullHorizontal from "./DividerFullHorizontal";
import { CaretDown } from "@phosphor-icons/react";
import RequiredForm from "./RequiredForm";
import * as yup from "yup";
import { useFormik } from "formik";
import { checkBoxTextMt } from "../const/sizes";
import useBackOnClose from "../lib/useBackOnClose";
import FileInput from "./FileInput";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";
import download from "../lib/download";

export default function UnggahBerkasLanjutan(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const noreg = props?.noreg;
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const [data, setData] = useState<any>(null);
  useEffect(() => {
    if (noreg) {
      const url = `api/transaction/${noreg}/getrincianbiaya`;
      if (isOpen) {
        clientRequest.then(() => {
          reqWithHeader
            .get(url)
            .then((response) => {
              if (response.data.status === 200) {
                setData(response.data.data);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        });
      }
    }
  }, [noreg, isOpen]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      berkasKontrak: [] as File[] | null,
      buktiPembayaran: [] as File[] | null,
      agreement: false,
    },

    validationSchema: yup.object().shape({
      berkasKontrak: yup
        .array()
        .min(1, "Berkas Kontrak yang sudah ditanadatangani harus diunggah")
        .required("Berkas Kontrak yang sudah ditanadatangani harus diunggah")
        .test("fileType", "Hanya file PDF yang diperbolehkan", (value) =>
          value.every((file) => file.type === "application/pdf")
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      buktiPembayaran: yup
        .array()
        .min(1, "Bukti Pembayaran harus diunggah")
        .required("Bukti Pembayaran harus diunggah")
        .test(
          "fileType",
          "Hanya file JPG, PNG, HEIC yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/heic" ||
                file.type === "image/HEIC"
            )
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      agreement: yup
        .boolean()
        .oneOf([true], "Anda harus menyetujui Syarat dan Ketentuan")
        .required("Anda harus menyetujui Syarat dan Ketentuan"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaction/${noreg}/berkaskontrak`;
      const payload = new FormData();
      if (values.berkasKontrak) {
        values.berkasKontrak.forEach((file) => {
          payload.append("kontrak", file);
        });
      }
      if (values.buktiPembayaran) {
        values.buktiPembayaran.forEach((file) => {
          payload.append("pembayaran", file);
        });
      }
      payload.append("disclaimer", values.agreement ? "1" : "0");

      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              onClose();
              setTrigger(!trigger);
              toast({
                title: "Berkas Lanjutan Berhasil Dikirim",
                description:
                  "Mohon tunggu admin untuk verifikasi berkas lanjutan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Maaf terjadi kesalahan",
              description: error?.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const fn = useFormatNumber;

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  return (
    <>
      <Button
        w={"100%"}
        colorScheme={"ap"}
        className="lg-clicky"
        onClick={onOpen}
      >
        Unggah Berkas Lanjutan
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        size={"lg"}
        // scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Berkas Lanjutan & Tagihan</ModalHeader>

          <ModalBody>
            <Alert
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <Box w={"100%"}>
                <AlertTitle
                  color={useColorModeValue("blue.500", "blue.200")}
                  mb={2}
                  fontSize={[18, null, 20]}
                  fontWeight={600}
                >
                  Unduh Berkas Kontrak Anda Disini
                </AlertTitle>

                <Button
                  colorScheme="blue"
                  className="lg-clicky"
                  onClick={() => {
                    download(
                      `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/kontrak`,
                      "Berkas Kontrak",
                      data
                    );
                  }}
                >
                  Unduh Berkas
                </Button>
              </Box>
            </Alert>

            <Alert
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <Box w={"100%"}>
                <AlertTitle
                  color={useColorModeValue("blue.500", "blue.200")}
                  mb={2}
                  fontSize={[18, null, 20]}
                  fontWeight={600}
                >
                  Tagihan Biaya Pengujian
                </AlertTitle>

                <AlertDescription>
                  {data ? (
                    <>
                      {Object.keys(data).map((key, i) => {
                        if (
                          key !== "Total Tagihan" &&
                          key !== "Biaya Lainnya"
                        ) {
                          return (
                            <HStack key={i} justify={"space-between"} mb={1}>
                              <Text fontSize={[12, null, 14]} opacity={0.5}>
                                {key}
                              </Text>
                              <Text fontWeight={600}>{fn(data[key])}</Text>
                            </HStack>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <DividerFullHorizontal my={2} />

                      <HStack justify={"space-between"}>
                        <Text fontSize={[12, null, 14]} opacity={0.5}>
                          Total Tagihan
                        </Text>
                        <Text fontWeight={600} fontSize={[18, null, 20]}>
                          Rp {fn(data["Total Tagihan"])}
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    <VStack justify={"center"} h={"100px"}>
                      <Spinner />
                    </VStack>
                  )}
                  <DividerFullHorizontal my={2} />

                  <Text fontSize={14}>
                    *Silahkan transfers ke nomor rekening berikut:{" "}
                    <b>2998877334 a.n RPL 134 Poltekkes Semarang (BNI)</b>
                  </Text>
                </AlertDescription>
              </Box>
            </Alert>

            <Alert
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <Box w={"100%"}>
                <AlertTitle
                  color={useColorModeValue("blue.500", "blue.200")}
                  mb={2}
                  fontSize={[18, null, 20]}
                  fontWeight={600}
                >
                  Tagihan Biaya Tambahan
                </AlertTitle>

                <AlertDescription>
                  {data ? (
                    <>
                      {Object.keys(data).map((key, i) => {
                        if (key === "Biaya Lainnya") {
                          return (
                            <HStack key={i} justify={"space-between"} mb={1}>
                              <Text fontSize={[12, null, 14]} opacity={0.5}>
                                {key}
                              </Text>
                              <Text fontWeight={600}>{fn(data[key])}</Text>
                            </HStack>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <DividerFullHorizontal my={2} />

                      <HStack justify={"space-between"}>
                        <Text fontSize={[12, null, 14]} opacity={0.5}>
                          Total Tagihan
                        </Text>
                        <Text fontWeight={600} fontSize={[18, null, 20]}>
                          Rp {fn(data["Total Tagihan"])}
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    <VStack justify={"center"} h={"100px"}>
                      <Spinner />
                    </VStack>
                  )}
                  <DividerFullHorizontal my={2} />

                  <Text fontSize={14}>
                    *Silahkan transfers ke nomor rekening berikut:{" "}
                    <b>1360032504927 a.n ARY KURNIAWATI (Mandiri)</b>
                  </Text>
                </AlertDescription>
              </Box>
            </Alert>

            <Accordion mb={4} allowMultiple>
              <AccordionItem className="accordionItem">
                <AccordionButton
                  p={4}
                  borderRadius={16}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  onClick={() => {
                    if (accordionIsOpen) {
                      setAccordionIsOpen(false);
                    } else {
                      setAccordionIsOpen(true);
                    }
                  }}
                >
                  <Text
                    fontSize={[16, null, 18]}
                    fontWeight={600}
                    textAlign={"left"}
                    mr={8}
                  >
                    Tata Cara Pembayaran Tagihan Pengujian
                  </Text>
                  <Center
                    bg={accordionIsOpen ? "p.500" : "var(--divider)"}
                    borderRadius={"full"}
                    p={2}
                    alignSelf={"flex-start"}
                  >
                    <Icon
                      as={CaretDown}
                      fontSize={[18, null, 20]}
                      weight="bold"
                      color={accordionIsOpen ? "white" : "p.500"}
                      transform={accordionIsOpen ? "rotate(-180deg)" : ""}
                      transition={"200ms"}
                    />
                  </Center>
                </AccordionButton>

                <AccordionPanel p={4} pt={2}>
                  <OrderedList>
                    <ListItem mb={2} mt={2}>
                      Buka aplikasi perbankan Anda atau kunjungi situs web resmi
                      bank Anda.
                    </ListItem>
                    <ListItem mb={2}>
                      Pilih opsi <b>"Transfer"</b> atau{" "}
                      <b>"Transfer antar Bank"</b>.
                    </ListItem>
                    <ListItem mb={2}>
                      Pilih bank tujuan, dalam hal ini{" "}
                      <b> BNI (Bank Negara Indonesia).</b>
                    </ListItem>
                    <ListItem mb={2}>
                      Masukkan nomor rekening tujuan:
                      <b> 2998877334 atas nama RPL 134 Poltekkes Semarang.</b>
                    </ListItem>
                    <ListItem mb={2}>
                      Pastikan nama pemilik rekening tujuan sesuai dengan
                      informasi yang Anda miliki.
                    </ListItem>
                    <ListItem mb={2}>
                      Masukkan jumlah yang ingin Anda transfer.
                    </ListItem>
                    <ListItem mb={2}>
                      Konfirmasi detail transfer,{" "}
                      <b>pastikan semuanya benar.</b>
                    </ListItem>
                    <ListItem mb={2}>
                      Ikuti instruksi untuk menyelesaikan transaksi, termasuk
                      verifikasi dengan kode OTP jika diperlukan.
                    </ListItem>
                    <ListItem>
                      Setelah transaksi berhasil, <b>simpan bukti transfer</b>{" "}
                      sebagai bukti telah melakukan pembayaran.
                    </ListItem>
                  </OrderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <form id="buktiPembayaranForm" onSubmit={formik.handleSubmit}>
              <FormControl
                mb={4}
                isInvalid={formik.errors.berkasKontrak ? true : false}
              >
                <FormLabel>
                  Unggah Berkas Kontrak
                  <RequiredForm />
                </FormLabel>
                <FileInput formik={formik} name="berkasKontrak" />
                <FormErrorMessage>
                  {formik.errors.berkasKontrak}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.buktiPembayaran ? true : false}
              >
                <FormLabel>
                  Unggah Bukti Pembayaran
                  <RequiredForm />
                </FormLabel>
                <FileInput formik={formik} name="buktiPembayaran" />
                <FormErrorMessage>
                  {formik.errors.buktiPembayaran}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.agreement ? true : false}>
                <Checkbox
                  alignItems={"flex-start"}
                  colorScheme="ap"
                  gap={1}
                  isChecked={formik.values.agreement}
                  isInvalid={formik.errors.agreement ? true : false}
                  onChange={() => {
                    formik.setFieldValue("agreement", !formik.values.agreement);
                  }}
                >
                  <Text mt={checkBoxTextMt} fontSize={[12, null, 14]}>
                    Saya dengan ini menyatakan setuju dengan{" "}
                    <ChakraLink
                      color="p.500"
                      href="/policy"
                      fontSize={["12px !important", null, "14px !important"]}
                      isExternal
                      fontWeight={600}
                    >
                      syarat dan ketentuan
                    </ChakraLink>{" "}
                    yang berlaku di LPPT POLTEKKES Kemenkes Semarang
                  </Text>
                </Checkbox>
                <FormErrorMessage>{formik.errors.agreement}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="ap"
              type="submit"
              form="buktiPembayaranForm"
              className="lg-clicky btn-ap"
              isDisabled={!formik.values.agreement}
              isLoading={loading}
            >
              Simpan & Lanjutkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
