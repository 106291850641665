import { Accordion, AccordionProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FaqItemComponent from "./FaqItem";
import { Faqs } from "../const/types";
import ComponentSpinner from "./ComponentSpinner";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

interface Props extends AccordionProps {
  limit?: number;
}

export default function FaqsList({ limit, ...props }: Props) {
  const [data, setData] = useState<Faqs[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const url = `api/feedback/faq/get`;
    const payload = {
      limit: limit || 0,
    };
    clientRequest.then(() => {
      reqWithHeader
        .post(url, payload)
        .then((response) => {
          if (response.data.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  return (
    <>
      {loading && <ComponentSpinner my={"auto"} />}

      {!loading && data && data.length > 0 && (
        <Accordion allowMultiple {...props}>
          {data.map((faq, i) => (
            <FaqItemComponent key={i} faq={faq} />
          ))}
        </Accordion>
      )}
    </>
  );
}
