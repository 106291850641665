import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Link as ChakraLink,
  Spinner,
} from "@chakra-ui/react";
import { MapPin, WhatsappLogo } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import { KontakWa } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";

export default function Footer() {
  const [data, setData] = useState<KontakWa[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const url = `api/contact/get`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  return (
    <VStack>
      <SimpleGrid columns={[1, null, 2]} gap={6} color={"white"}>
        <Stack flexDir={["column", null, "row"]} gap={4} align={"flex-start"}>
          <Box>
            <Image
              src={"/images/logoWhite.png"}
              w={"100%"}
              maxW={"200px"}
              mb={4}
            />

            <HStack align={"flex-start"}>
              <Icon as={MapPin} fontSize={iconSize} mt={1} />
              <Text>
                Jl. Tirto Agung, Pedalangan, Banyumanik, Kota Semarang, 50239,
                Jawa Tengah, Indonesia
              </Text>
            </HStack>
          </Box>
        </Stack>

        <Box mt={3}>
          <Text fontWeight={700} mb={2} lineHeight={1}>
            Kontak
          </Text>

          {loading && <Spinner mt={2} />}

          {!loading &&
            data &&
            data.length > 0 &&
            data.map((kontak, i) => (
              <HStack key={i} mb={1}>
                <Icon as={WhatsappLogo} fontSize={iconSize} />
                <ChakraLink href="https://wa.me/628988584563" target="_blank">
                  +{`${kontak.wa} (${kontak.name})`}
                </ChakraLink>
              </HStack>
            ))}
        </Box>
      </SimpleGrid>
    </VStack>
  );
}
