import {
  Layout,
  Money,
  Package,
  Receipt,
  Rows,
  UsersThree,
} from "@phosphor-icons/react";

const adminNav = [
  {
    name: "Dashboards",
    icon: Layout,
    nested: false,
    link: "/admin",
  },
  {
    name: "Transaksi",
    icon: Receipt,
    nested: false,
    link: "/admin/transaksi",
  },
  {
    name: "Produk",
    icon: Package,
    nested: true,
    subNav: [
      {
        name: "Kategori Produk",
        link: "/admin/kategori-produk",
      },
      {
        name: "Kelola Produk",
        link: "/admin/kelola-produk",
      },
      {
        name: "File Template",
        link: "/admin/file-template",
      },
      {
        name: "Kuisioner",
        link: "/admin/kuisioner",
      },
    ],
  },
  {
    name: "Pengguna",
    icon: UsersThree,
    nested: true,
    subNav: [
      {
        name: "Customer",
        link: "/admin/pengguna-customer",
      },
      {
        name: "Penguji",
        link: "/admin/pengguna-penguji",
      },
    ],
  },
  {
    name: "Biaya Tambahan",
    icon: Money,
    nested: false,
    link: "/admin/biaya-tambahan",
  },
  {
    name: "Konten",
    icon: Rows,
    nested: true,
    subNav: [
      {
        name: "FAQs",
        link: "/admin/faqs",
      },
      {
        name: "Kontak WA",
        link: "/admin/kontakwa",
      },
    ],
  },
];

export default adminNav;
