import {
  Badge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import useStatusBadgeColor from "../lib/useStatusBadgeColor";
import { Transaksi } from "../const/types";
import useBackOnClose from "../lib/useBackOnClose";

type Props = {
  data: Transaksi;
};

export default function StatusModal({ data }: Props) {
  const statusBadgeColor = useStatusBadgeColor;
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const modalContentRef = useRef(null);

  const activeStep = {
    "Metode Uji Belum Dipilih": 0,
    "Berkas Belum Lengkap": 1,
    "Verifikasi Berkas Ditolak": 1,
    "Verifikasi Berkas": 2,
    "Berkas Diverifikasi": 2,
    "Berkas Lanjutan Belum Diunggah": 3,
    "Verifikasi Berkas Lanjutan Ditolak": 3,
    "Verifikasi Berkas Lanjutan": 4,
    "Berkas Lanjutan Diverifikasi": 4,
    "Tanggal Pengujian": 5,
    "Dalam Pengujian": 6,
    "Berita Acara Terbit": 7,
    "LHU Terbit": 8,
    "Sertifikat Terbit": 10,
  };

  const steps = [
    {
      title: "Pilih Metode Uji",
      description: "Metode uji terpilih",
    },
    {
      title: "Unggah Berkas",
      description: "Berkas telah berhasil dikirm",
    },
    {
      title: "Verifikasi Berkas",
      description: "Berkas terverifikasi",
    },
    {
      title: "Pembayaran Tagihan",
      description: "Tagihan telah berhasil dikirim",
    },
    {
      title: "Verfikasi Pembayaran",
      description: "Verifikasi pembayaran berhasil",
    },
    {
      title: "Terbit Tanggal Pengujian",
      description: `Tanggal pengujian {Tanggal Pengujian}`,
    },
    {
      title: "Dalam Proses Pengujian",
      description: "Proses telah selesai",
    },
    {
      title: "Berita Acara Terbit",
      description: "Berita acara terbit",
    },
    {
      title: "LHU Terbit",
      description: "LHU berhasil diterbitkan",
    },
    {
      title: "Sertifikat terbit",
      description: "Pengujian Selesai",
    },
  ];

  const isTanggalPengujian = data.status.includes("Tanggal Pengujian");

  return (
    <>
      <Badge
        w={"100%"}
        colorScheme={statusBadgeColor(data.status)}
        cursor={"pointer"}
        onClick={onOpen}
      >
        {data.status}
      </Badge>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        initialFocusRef={modalContentRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalContentRef}>
          <ModalHeader>
            <Text fontSize={[18, null, 20]} mb={2}>
              Status Transaksi
            </Text>
            <Badge colorScheme={statusBadgeColor(data.status)}>
              {data.status}
            </Badge>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stepper
              //@ts-ignore
              index={isTanggalPengujian ? 5 : activeStep[data.status]}
              orientation="vertical"
              height="650px"
              gap="0"
              colorScheme="ap"
            >
              {steps.map((step: any, index: number) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>
                      <Text opacity={0.6}>
                        {isTanggalPengujian && index === 5
                          ? data.status
                          : step.description}
                      </Text>
                    </StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalBody>

          <ModalFooter>
            <Button w={"100%"} className="btn-solid" onClick={handleOnClose}>
              Tutup
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
